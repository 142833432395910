import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './store'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Categories from './components/pages/Categories';
import Models from './components/pages/Models';
import Video from './components/pages/Video';
import Videos from './components/pages/Videos';
import Albums from './components/pages/Albums';
import Album from './components/pages/Album';
import Channels from './components/pages/Channels';
import Channel from './components/pages/Channel';
import User from './components/pages/User';
import Home from './components/pages/Home';
import Tags from './components/pages/Tags';
import Model from './components/pages/Model';
import Search from './components/pages/Search';
import Page from './components/pages/Page';
import NotFound from './components/pages/NotFound';
import { hydrate, render } from "react-dom";

const THEME = createTheme({
  typography: {
    "fontFamily": `"Outfit", sans-serif`,
    "fontSize": 14,
    "fontWeightLight": 300,
    "fontWeightRegular": 400,
    "fontWeightMedium": 500
  }
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: <Home />,  
      },
      {
        path: "/categories",
        element: <Categories />,  
      },
      {
        path: "/categories/:page",
        element: <Categories />,  
      },
      {
        path: "/tags",
        element: <Tags />,  
      },
      {
        path: "/tags/:letter/page/:page",
        element: <Tags />,  
      },
      {
        path: "/video/:id",
        element: <Video />
      },
      {
        path: "/video/:id/share/:shareTab",
        element: <Video />
      },
      {
        path: "/video/preview/:hash",
        element: <Video />
      },
      {
        path: "/embed/:embed",
        element: <Video />
      },
      {
        path: "/videos",
        element: <Videos />
      },
      {
        path: "/videos/tag/:tag",
        element: <Videos />
      },
      {
        path: "/videos/tag/:tag/page/:page",
        element: <Videos />
      },
      {
        path: "/videos/search/:search",
        element: <Videos />
      },
      {
        path: "/videos/search/:search/page/:page",
        element: <Videos />
      },
      {
        path: "/videos/:category",
        element: <Videos />
      },
      {
        path: "/videos/:category/page/:page",
        element: <Videos />
      },
      {
        path: "/models",
        element: <Models />,  
      },
      {
        path: "/models/page/:page",
        element: <Models />
      },
      {
        path: "/models/search/:search",
        element: <Models />,  
      },
      {
        path: "/models/search/:search/page/:page",
        element: <Models />
      },
      {
        path: "/model/:id",
        element: <Model />
      },
      {
        path: "/model/:id/:subTab",
        element: <Model />
      },
      {
        path: "/model/:id/:subTab/page/:page",
        element: <Model />
      },
      {
        path: "/galleries",
        element: <Albums />,  
      },
      {
        path: "/galleries/page/:page",
        element: <Albums />
      },
      {
        path: "/galleries/search/:search",
        element: <Albums />,  
      },
      {
        path: "/galleries/search/:search/page/:page",
        element: <Albums />
      },
      {
        path: "/gallery/:id",
        element: <Album />
      },
      {
        path: "/channels",
        element: <Channels />,  
      },
      {
        path: "/channels/page/:page",
        element: <Channels />
      },
      {
        path: "/channel/:id",
        element: <Channel />
      },
      {
        path: "/channel/:id/:subTab",
        element: <Channel />
      },
      {
        path: "/channel/:id/:subTab/page/:page",
        element: <Channel />
      },
      {
        path: "/channels/search/:search",
        element: <Channels />,  
      },
      {
        path: "/channels/search/:search/page/:page",
        element: <Channels />
      },
      {
        path: "/profile/:id",
        element: <User />
      },
      {
        path: "/profile/:id/:subTab",
        element: <User />
      },
      {
        path: "/search/:search",
        element: <Search />
      },
      {
        path: "/content/:id",
        element: <Page />
      },
      {
        path: "*",
        element: <NotFound />
      },
    ]
  }
], { basename: "" });

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(<React.StrictMode>
    <ThemeProvider theme={THEME}>
      <Provider store={store}>
        <RouterProvider router={router} />
      </Provider>
    </ThemeProvider>
  </React.StrictMode>, rootElement);
} else {
  render(<React.StrictMode>
    <ThemeProvider theme={THEME}>
      <Provider store={store}>
        <RouterProvider router={router} />
      </Provider>
    </ThemeProvider>
  </React.StrictMode>, rootElement);
}
reportWebVitals();
