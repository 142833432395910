import { PlayArrow, Pause, Settings, Fullscreen } from '@mui/icons-material';
import { LinearProgress } from '@mui/material';
import Slider from '@mui/material/Slider';
import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { useSelector } from 'react-redux';


export default function PlayerControls(props){
    const { videoRef, toggleFullscreen, isFullScreen, length, videoData, isPlaying, setIsPlaying, videoURL, vertical } = props;
    const currentTimeInit = {
        string: '00:00:00',
        percentage: 0
    }
    const [currentTime, setCurrentTime] = useState(currentTimeInit);
    const [totalDuration, setTotalDuration] = useState('00:00:00');
    const [videoVolume, setVideoVolume] = useState(80);
    const [isOpenSettings, setIsOpenSettings] = useState(false);
    const [isOpenQuality, setIsOpenQuality] = useState(false);
    const [quality, setQuality] = useState({suffix: "", value: ""});
    const [showControls, setShowControls] = useState(true);
    const settings = useSelector((state) => state.settings);

    useEffect(() => {
        let timeoutId;
        const tubePlayer = document.querySelector('.tube-player');
        const hideCursor = () => {
            tubePlayer.style.cursor = 'none';
        };
        const showCursor = () => {
            tubePlayer.style.cursor = 'default';
        };
        const handleMouseMove = () => {
            setShowControls(true);
            showCursor();
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                setShowControls(false);
                hideCursor();
            }, 3000);
        };  
        window.addEventListener('mousemove', handleMouseMove);
        return () => {
          window.removeEventListener('mousemove', handleMouseMove);
          clearTimeout(timeoutId);
        };
      }, []);

    useEffect(() => {
        const video = videoRef.current;
        if (video) {
            const totalDurationInSeconds = length;
            const hours = Math.floor(totalDurationInSeconds / 3600);
            const minutes = Math.floor((totalDurationInSeconds % 3600) / 60);
            const seconds = Math.floor(totalDurationInSeconds % 60);
            setTotalDuration(
                `${String(hours).padStart(2, '0')}:${String(minutes).padStart(
                2,
                '0'
                )}:${String(seconds).padStart(2, '0')}`
            );
        }
    }, [length]);

    useEffect(() => {
        volume(videoVolume);
    }, [videoVolume]);

    useEffect(() => {
        if(isPlaying){
            videoRef.current.play();
        }
        setCurrentTime(currentTimeInit);
    }, [videoURL]);

    useEffect(() => {
        if(videoData && videoData.resolution){
            if(localStorage.getItem("quality")){
                if(videoData.resolution[localStorage.getItem("quality")]){
                    setQuality({
                        suffix: localStorage.getItem("quality"),
                        value: videoData.resolution[localStorage.getItem("quality")]
                    });
                }
                else {
                    setQuality({
                        suffix: "",
                        value: videoData.resolution[""]
                    });
                }
            }
            else {
                setQuality({
                    suffix: "",
                    value: videoData.resolution[""]
                });
            }
        }
    }, [videoData]);

    const changeQuality = (suffix) => {
        setQuality({
            suffix: suffix,
            value: videoData.resolution[suffix]
        });
        setIsOpenQuality(false);
        setIsOpenSettings(false);
        if((settings && !settings.cookiesNotification) || Cookies.get('cookiesNotification') === "true"){
            localStorage.setItem("quality", suffix);
        }
        window.dispatchEvent(new Event("storage"))
    }

    const updateTime = (value = 0) => {
        const video = videoRef.current;
        if (video) {
            let currentTimeInSeconds
            if(value > 0){
                currentTimeInSeconds = length / 100 * value;
                videoRef.current.currentTime = currentTimeInSeconds;
            }
            else {
                currentTimeInSeconds = video.currentTime;
            }
            const videoCurrentTimeTemp = currentTimeInSeconds / length * 100;
            const hours = Math.floor(currentTimeInSeconds / 3600);
            const minutes = Math.floor((currentTimeInSeconds % 3600) / 60);
            const seconds = Math.floor(currentTimeInSeconds % 60);
            setCurrentTime({
                string: `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2,'0')}:${String(seconds).padStart(2, '0')}`,
                percentage: videoCurrentTimeTemp
            });
        }
    }
    
    useEffect(() => {
        let interval;
        if (isPlaying) {
            updateTime();
            interval = setInterval(updateTime, 1000);
        } else {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [isPlaying, videoRef.current]);

    const volume = (value) => {
        videoRef.current.volume = value / 100;
    };
    
    const play = () => {
        const video = videoRef.current;
        if(video){
            if(!isPlaying){
                video.play();
                setIsPlaying(true);
            }
            else {
                video.pause();
                setIsPlaying(false);
            }
        }
    }

    const handleVolume = (value) => {
        setVideoVolume(value);
        if((settings && !settings.cookiesNotification) || Cookies.get('cookiesNotification') === "true"){
            localStorage.setItem("volume", value);
        }
    }

    useEffect(() => {
        if(localStorage.getItem("volume")){
            setVideoVolume(localStorage.getItem("volume"));
        }    
    }, [])
    
    return (
        <div className='tube-player-controls-wrapper'>
            <div className='tube-player-play-stop-wrapper' onClick={play}>
                {!isPlaying ? <div className="tube-player-play-big">
                    <PlayArrow />
                </div> : null}
            </div>
            {(!isPlaying || showControls) && (
                <div className="tube-player-controls">
                    <div className="tube-player-play" onClick={play}>
                        {isPlaying ? (
                            <Pause />
                        ) : (
                            <PlayArrow />
                        )}
                    </div>
                    <div className='tube-player-lc'>
                        <Slider value={currentTime.percentage} onChange={(e, value) => {updateTime(value)}} aria-label="Default" className='tube-player-timeline'/>
                        <div className='tube-player-time'>
                            {currentTime.string} / {totalDuration}
                        </div>
                        <Slider size="small" value={videoVolume} onChange={(e, value) => handleVolume(value)} aria-label="Default" className={`tube-player-volume ${vertical ? 'vertical' : ''}`}/>
                        <div class="tube-player-buttons">
                            <Settings onClick={() => setIsOpenSettings(prev => !prev)} />
                            {isOpenSettings && (
                                <div class="tube-player-settings">
                                    <div onClick={() => setIsOpenQuality(prev => !prev)}>
                                        <div>Quality</div>
                                        <div>
                                            {quality.value}   
                                        </div>
                                    </div>
                                    {(isOpenQuality && videoData) && Object.keys(videoData.resolution).map(key => (
                                        <div><div onClick={() => changeQuality(key)}>{videoData.resolution[key]}</div></div>
                                    ))}
                                </div>
                            )}
                            <Fullscreen onClick={toggleFullscreen} />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}