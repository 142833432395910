
import "./Categories.css";
import TubeSubmit from "../form/TubeSubmit";
import { Link, NavLink, useParams } from "react-router-dom";
import TubeButton from "../form/TubeChip";
import Sidebar from "./Sidebar";
import { useEffect, useState } from "react";
import { appUrl, get } from "../../utils/fetch";
import { edit as editHeader } from '../../slices/headerSlice';
import { useDispatch, useSelector } from "react-redux";

export default function Categories(props){
    const { page } = useParams();
    const [ categories, setCategories ] = useState([]);

    const settings = useSelector(state => state.settings);
    const dispatch = useDispatch();

    useEffect(() => {
        get(`${appUrl}/api/content-categories`, (result) => {
            result.data.sort((a, b) => a.name.localeCompare(b.name));
            setCategories(result.data);
        });
        dispatch(editHeader({title: `Categories - ${settings.siteTitle}`}));
    }, []);

    const alphabet = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];
    
    return (
        <div className="categories-wrapper">
            <div className="categorylist-content">
                <h1>Categories</h1>
                <div className="categorylist-pagination">
                    <Link
                        to={categories.some(obj => /^[0-9]/.test(obj.name.toLowerCase())) ? `/categories/0-9` : "#"}
                        className={`
                            ${page === "0-9" ? "active" : ""}
                            ${!categories.some(obj => /^[0-9]/.test(obj.name.toLowerCase())) ? "inactive" : ""}
                        `}
                    >
                        0-9
                    </Link>
                    {alphabet.map(letter => (
                        <Link
                            to={categories.some(obj => obj.name.toLowerCase().startsWith(letter.toLocaleLowerCase())) ? `/categories/${letter}` : "#"}
                            className={`
                                ${((page === letter) || (!page && letter === "A")) ? "active" : ""}
                                ${!categories.some(obj => obj.name.toLowerCase().startsWith(letter.toLocaleLowerCase())) ? "inactive" : ""}
                            `}
                        >
                            {letter}   
                        </Link>
                    ))}
                </div>
                <div className="categorylist-content-list">
                    {page === "0-9" ? 
                        categories.filter(category => /^[0-9]/.test(category.name)).map(category => (
                            <Link to={`/videos/${category.title_slug}`} className="categorylist-content-category">
                                <div className="categorylist-category-thumbnail" style={{backgroundImage: `url("${appUrl}/category-thumbnails/${category.thumbnail}")`}}/>
                                <span>{category.name}</span>
                            </Link>
                        ))
                        :
                        categories.filter(category => category.name.toLocaleLowerCase().startsWith(page ? page.toLocaleLowerCase() : "a")).map(category => (
                            <Link to={`/videos/${category.title_slug}`} className="categorylist-content-category">
                                <div className="categorylist-category-thumbnail" style={{backgroundImage: `url("${appUrl}/category-thumbnails/${category.thumbnail}")`}}/>
                                <span>{category.name}</span>
                            </Link>
                        ))
                    }
                </div>
            </div>     
        </div>
    );
}