import { NavLink } from "react-router-dom";
import { appUrl, formatNumber } from "../../utils/fetch";
import { CameraAltOutlined, ChatOutlined, FavoriteBorderOutlined, VisibilityOutlined } from "@mui/icons-material";
import { moduleUser } from "../../utils/modules";

export default function AlbumList(props){
    const { data } = props;
    
    return (
        <>
            {data && data.map(album => (
                <NavLink to={`/gallery/${album.title_slug}`} key={album.id}>
                    <div className="categories-content-albums-album"> 
                        <div className="categories-content-albums-album-thumb" style={{backgroundImage: `url(${appUrl}/${album.thumb})`}}/>
                        <div className="categories-content-albums-album-media"><CameraAltOutlined /> {album.photos}</div>    
                    </div>
                    {moduleUser && (
                        <div className="categories-content-videos-user-wrapper">
                            <div className="categories-content-videos-user" style={{backgroundImage: `url(${appUrl}/${album.userAvatar})`}}/>
                            <div className="categories-content-videos-username">{album.user}</div>
                        </div>
                    )}
                    <div className="categories-content-albums-name">{album.name}</div>
                    <div className='categories-content-albums-info'>
                        <VisibilityOutlined /> {formatNumber(album.views)}
                        {moduleUser && (
                            <>
                                <FavoriteBorderOutlined /> 0
                                <ChatOutlined /> 0
                            </>
                        )}
                    </div>
                </NavLink>
            ))}
        </>
    ); 
}