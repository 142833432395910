import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import { Link, useLocation } from "react-router-dom";
import PropTypes from 'prop-types';
import { ArrowDropDown } from '@mui/icons-material';

const NavigationLink = React.forwardRef(({ src, linkName, icon, variant, total, target, rel, dropDown }, ref) => {
    const location = useLocation();

    return (
        <Link 
            to={src} 
            target={target}
            rel={rel}
            ref={ref}
            style={{
                color: "var(--black)",
                textDecoration: "none",
            }}
        >
            <MenuItem selected={location.pathname.split("/")[1] === src.split("/")[1]} className={`navigation-link ${variant ? `navigation-link-${variant}` : ""}`}>
                {icon}
                <ListItemText>
                    <span style={{
                        fontWeight: 300,
                        fontSize: 14
                    }}>
                        {linkName}
                        {total ? (
                            <span className='navigation-link-total'>{total}</span>
                        ) : null}
                    </span>
                </ListItemText>
                {dropDown && (
                    <ArrowDropDown
                        className='navigaion-link-dropdown-icon'
                    />
                )}
            </MenuItem>
        </Link>
    );
});

NavigationLink.propTypes = {
    linkName: PropTypes.string.isRequired,
    src: PropTypes.string.isRequired,
    icon: PropTypes.object
};

export default NavigationLink;
