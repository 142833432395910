import './App.css';
import { Outlet, useParams } from "react-router-dom";
import Navigation from './components/navigation/Navigation';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import TubeButton from './components/form/TubeButton';
import { appUrl, get, post } from './utils/fetch';
import Cookies from 'js-cookie';
import Header from './components/navigation/Header';
import Footer from './components/navigation/Footer';
import { useSelector, useDispatch } from 'react-redux';
import { edit } from './slices/settingsSlice';
import { edit as editHeader } from './slices/headerSlice';
import DialogComponent from './components/dialog/Dialog';
import { Helmet } from 'react-helmet';

function App() {
    const dispatch = useDispatch();
    const { embed } = useParams();

    const [ pass, setPass ] = useState("");
    const [ passErr, setPassErr ] = useState(false);
    //const [ isLoggedIn, setIsLoggedIn ] = useState(Cookies.get('isLoggedIn'));
    const [ isLoggedIn, setIsLoggedIn ] = useState("true");
    const [ cookiesNotification, setCookiesNotification ] = useState({
        accept: Cookies.get('cookiesNotification'),
        isOpen: false,
        content: ""
    });
    const [ adultWarning, setAdultWarning ] = useState({
        accept: Cookies.get('adultWarning'),
        isOpen: false
    });

    const settings = useSelector((state) => state.settings);
    const header = useSelector((state) => state.header);

    const login = () => {
        post(`${appUrl}/api/login`, {
            password: pass
        }, (result) => {
            if(result.data === true){
                Cookies.set('isLoggedIn', 'true');
                setIsLoggedIn('true');
            }
            else {
                setPassErr(true);
            }
        })
    }

    useEffect(() => {
        get(
            `${appUrl}/api/fe-settings`,
            (data) => {
                dispatch(edit({...data.data}));
                if(data.data.verificationCode){
                    const container = document.createElement("div");
                    container.innerHTML = data.data.verificationCode;
                    Array.from(container.children).forEach((child) => {
                        document.head.appendChild(child);
                    });
                }
            }
        );
    }, [])

    useEffect(() => {
        if(settings && settings.cookiesNotification && cookiesNotification.accept !== "true"){
            setCookiesNotification({
                accept: "false",
                isOpen: true,
                content: settings.cookiesNotificationContent
            });
        }
        if(settings && settings.adultWarning && adultWarning.accept !== "true"){
            setAdultWarning({
                accept: "false",
                isOpen: true,
                content: settings.adultWarningContent
            });
        }
        if(settings && settings.cookiesNotification === false){
            Cookies.set('cookiesNotification', 'true');
        }
        if (settings?.javascriptIntegration || settings?.verificationCode) {
            const container = document.createElement("div");
            container.innerHTML = `${settings?.verificationCode || ""} ${settings?.javascriptIntegration || ""}`;
            const scripts = container.querySelectorAll("script");
            scripts.forEach((script) => {
                const newScript = document.createElement("script");
                if (script.src) {
                    newScript.src = script.src;
                } else {
                    newScript.textContent = script.innerHTML;
                }
                document.body.appendChild(newScript);
                newScript.remove();
            });
        }
        dispatch(editHeader({
            title: settings?.siteTitle,
            description: settings?.siteDescription,
            keywords: settings?.siteKeywords
        }));
    }, [settings]);

    return (
        <>
            <Helmet>
                <meta name="tagline" content={settings.siteTagline	 ? settings.siteTagline	 : "Loading..."} />
                <meta name="description" content={header.description || "Loading..."} />
                <meta name="keywords" content={header.keywords || "Loading..."} />
                <link rel="icon" type="image/png" href={`${appUrl}/assets/file_favicon.png`} />
                <link rel="apple-touch-icon" href={`${appUrl}/assets/file_webclip.png`} />
                <link rel="sitemap" type="application/xml" href={`${appUrl}/sitemap.xml`} />
                <title>{settings.disableWebsite ? "Out of service" : header.title || "Loading..."}</title>
            </Helmet>
            {/* CSS Variables */}
            <style>
                {`
                    :root {
                        --primary: ${settings.themeColorPrimary || "#123"};
                        --primary-light: ${settings.themeColorPrimaryLight || "#414e5c"};
                        --primary-dark: ${settings.themeColorPrimaryDark || "#012"};
                        --button: ${settings.themeColorButton || "#ff6400"};
                        --link: ${settings.themeColorLink || "#9ba3aa"};
                        --white: ${settings.themeColorWhite || "#fff"};
                        --black: ${settings.themeColorBlack || "#012"};
                        --error: #8b1919;
                    }
                `}
            </style>
            {settings.disableWebsite === false ? (
                <>
                    {isLoggedIn !== "true" ? (
                        <div className='login-window'>
                            <TextField 
                                id={"password"}
                                label={"Password"}
                                variant="outlined"
                                type={"password"}
                                className="text-input"
                                value={pass}
                                onChange={(e) => setPass(e.target.value)}
                            />
                            <TubeButton
                                title="Log in"
                                onClick={() => login()}
                            />
                            {passErr ? (
                                <div className='incorrect-password'>
                                    Password is incorrect!
                                </div>
                            ) : null}
                        </div>
                    ) : (
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"en"}>
                            <div 
                                className="App"
                            >
                                {!embed && (
                                    <>
                                        <Header />
                                        <Navigation />  
                                    </>
                                )}
                                <div className={`content ${embed ? 'content-embed' : ''}`}>
                                    <Outlet />
                                </div>
                                {!embed && (
                                    <Footer />
                                )}
                            </div>
                        </LocalizationProvider>
                    )}
                    <DialogComponent
                        onClose={() => {}}
                        open={cookiesNotification.isOpen}
                        title={"Cookie notice"}
                        content={cookiesNotification.content}
                        className={"non-modal-dialog"}
                        disableScrollLock
                        disableEnforceFocus
                        buttons={[
                            {
                                variant: "contained",
                                label: "Accept",
                                color: "primary",
                                onClick: () => {
                                    setCookiesNotification({
                                        accept: "true",
                                        isOpen: false,
                                        content: ""
                                    });
                                    Cookies.set('cookiesNotification', 'true');
                                },
                            },
                            {
                                variant: "outlined",
                                label: "Reject",
                                color: "primary",
                                onClick: () => {
                                    setCookiesNotification({
                                        accept: "false",
                                        isOpen: false,
                                        content: ""
                                    });
                                    Cookies.set('cookiesNotification', 'false');
                                },
                            }
                        ]}
                    />
                    
                    <DialogComponent
                        onClose={() => {}}
                        open={adultWarning.isOpen}
                        title={"This is an adult website"}
                        content={adultWarning.content}
                        className={"dialog-non-transparent-background dialog-adult-warning"}
                        maxWidth={500}
                        buttons={[
                            {
                                variant: "contained",
                                label: "I am 18 or older - Enter",
                                color: "primary",
                                onClick: () => {
                                    setAdultWarning({
                                        accept: "true",
                                        isOpen: false,
                                    });
                                    Cookies.set('adultWarning', 'true');
                                },
                            },
                            {
                                variant: "outlined",
                                label: "I am under 18 - Exit",
                                color: "primary",
                                onClick: () => {
                                    window.location.href = "https://www.google.com"
                                },
                            }
                        ]}
                    />
                </>
            ) : (
                <>
                    {settings.disableWebsiteContent && (
                        <div className="out-of-service">
                            <h1>Out of service</h1>
                            {settings.disableWebsiteContent}
                        </div>
                    )}
                </>
            )}
        </>
    );
}

export default App;
