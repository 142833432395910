import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import "./Select.css";

export default function SelectComponent(props) {
    const { label, stateName, valueName, dispatchFunc, data, disabled, noRedux, noReduxValue, sx, noReduxOnChange } = props;
    const safeStateName = stateName || 'default';
    let value = useSelector((state) => state[safeStateName]?.[valueName]);
    let handleChange;
    if (noRedux) {
        value = noReduxValue;
        handleChange = (value) => {
            noReduxOnChange(value);
        }
    }
    else {
        handleChange = (value) => {
            dispatch(dispatchFunc({[valueName]: value}));
        }
    }
    const dispatch = useDispatch();

    return (
        <FormControl fullWidth className='select-input' sx={sx}>
            <InputLabel id={valueName}>{label}</InputLabel>
            <Select
                labelId={valueName}
                id={`${valueName}-select`}
                value={value === null ? 0 : value}
                label={label}
                disabled={disabled}
                onChange={(e) => handleChange(e.target.value)}
            >
                {Object.keys(data).map(key => (
                    <MenuItem value={key} key={key}>
                        {data[key]}
                    </MenuItem>    
                ))}
            </Select>
        </FormControl>
    );
}

SelectComponent.propTypes = {
    label: PropTypes.string.isRequired,
    stateName: PropTypes.string.isRequired,
    valueName: PropTypes.string,
    dispatchFunc: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
    disabled: PropTypes.bool
};