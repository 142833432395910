import { ChatOutlined, FavoriteBorderOutlined, VisibilityOutlined } from '@mui/icons-material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useNavigate as useNavigateLegacy } from 'react-router';
import { appUrl, formatNumber, get, post } from '../../utils/fetch';
import TubeButton from '../form/TubeChip';
import TubeSubmit from '../form/TubeSubmit';
import Sidebar from './Sidebar';
import "./Album.css";
import { moduleUser } from '../../utils/modules';
import { edit as editHeader } from '../../slices/headerSlice';
import { useDispatch, useSelector } from "react-redux";

function Album(props) {
    const { id } = useParams();

    const [ album, setAlbum ] = useState({
        models: [],
        photos: []
    });

    const [ photo, setPhoto ] = useState(0);
    const [ showMore, setShowMore ] = useState(false);
    const [ likes, setLikes ] = useState(0);
    const [ dislikes, setDislikes ] = useState(0);
    const [ clientLike, setClientLike ] = useState({
        isLiked: false,
        type: 0
    });

    const navigate = useNavigate();
    const navigateLegacy = useNavigateLegacy();

    const settings = useSelector(state => state.settings);
    const dispatch = useDispatch();

    useEffect(() => {
        get(`${appUrl}/api/album/${id}`, (result) => {
            if (result.response?.status === 404) {
                navigateLegacy('/404');
                return;
            }
            setAlbum(result.data);
            setLikes(result.data.likes);
            setDislikes(result.data.dislikes);
            setClientLike(result.data.clientLike);
            dispatch(editHeader({title: `${result.data.name} - ${settings.siteTitle}`}));
        });
        window.scrollTo({
            top: 0
        });
    }, [id]);

    const handleLike = (type) => {
        switch(type){
            case 0:
                if(clientLike.isLiked && clientLike.type === 0){
                    setLikes((prevState) => (prevState - 1)); 
                    setClientLike({
                        isLiked: false,
                        type: 0
                    });   
                }
                else {
                    if(clientLike.isLiked && clientLike.type === 1){
                        setDislikes((prevState) => (prevState - 1));    
                    } 
                    setClientLike({
                        isLiked: true,
                        type: 0
                    });   
                    setLikes((prevState) => (prevState + 1));
                }
                break;
            case 1:
                if(clientLike.isLiked && clientLike.type === 1){
                    setDislikes((prevState) => (prevState - 1));   
                    setClientLike({
                        isLiked: false,
                        type: 0
                    });     
                }
                else {
                    if(clientLike.isLiked && clientLike.type === 0){
                        setLikes((prevState) => (prevState - 1));    
                    }
                    setClientLike({
                        isLiked: true,
                        type: 1
                    });   
                    setDislikes((prevState) => (prevState + 1));
                }
                break;
            default:
                return;
        }
        post(`${appUrl}/api/album-like`, {
            id: album.id,
            type: type    
        });
    }
    
    const changePhoto = (id) => {
        switch (id) {
            case "next":
                if(album.photos.length < photo + 2){
                    setPhoto(0);
                }
                else {
                    setPhoto(photo + 1);
                }
                break;
            case "prev":
                if(photo === 0){
                    setPhoto(album.photos.length -1);
                }
                else {
                    setPhoto(photo - 1);
                }
                break;
            default:
                setPhoto(id);
                break;
        }
    }

    return (
        <div className='album-wrapper'>
            <div className='album-content'>
                <div className='album-content-album' style={{backgroundImage: `url("${album.photos ? album.photos[photo] : ""}")`}}>
                    <TubeSubmit
                        onClick={() => {changePhoto("prev")}}
                        label="<"
                        variant="plainOutlined"                
                    />   
                    <TubeSubmit
                        onClick={() => {changePhoto("next")}}
                        label=">"
                        variant="plainOutlined"                
                    />    
                </div>
                <div className='album-content-thumbnails'>
                    {album.photos.map((url, i) => (
                        <div className={`album-content-thumbnails-photo ${i === photo ? "active" : ""}`} style={{backgroundImage: `url("${url}")`}} onClick={() => {changePhoto(i)}} />
                    ))}
                </div>
                <h1>{album.name}</h1>
                {(showMore && album.description !== "") && (
                    <>
                        <div className='album-content-description'>
                            {album.description}
                        </div>
                    </>
                )}
                <div className="line"/>
                <div className='album-content-interactions'>
                    <div className='album-content-interactions-info'>
                        <VisibilityOutlined /> {formatNumber(album.views)}
                        {moduleUser && (
                            <>
                                <FavoriteBorderOutlined /> 0
                                <ChatOutlined /> 0
                            </>
                        )}
                    </div>
                    <div className="album-content-interactions-buttons">
                        {moduleUser && (
                            <>
                                <TubeSubmit
                                    onClick={() => {}}
                                    label="0"
                                    icon="favorite"
                                    variant="plainOutlined"                
                                />
                            </>
                        )}
                        <TubeSubmit
                            onClick={() => handleLike(0)}
                            label={likes}
                            icon="like"
                            variant={(clientLike.isLiked && clientLike.type === 0) ? "contained" : "plainOutlined"}              
                        />
                        <TubeSubmit
                            onClick={() => handleLike(1)}
                            label={dislikes}
                            icon="dislike"
                            variant={(clientLike.isLiked && clientLike.type === 1) ? "contained" : "plainOutlined"}               
                        />
                        <TubeSubmit
                            onClick={() => {}}
                            label="Share"
                            icon="share"
                            variant="plainOutlined"                
                        />
                        <TubeSubmit
                            onClick={() => {}}
                            label="Report"
                            icon="flag"
                            variant="plainOutlined"                
                        />
                    </div>
                </div>
                {album.channel && (
                    <>
                        <div className="line"/>
                        <div className='album-content-user' onClick={() => navigate(`/channel/${album.channel.title_slug}`)}>
                            <div className='album-content-user-avatar' style={{backgroundImage: `url("${appUrl}/channel-banners/${album.channel.logo}")`}}/>
                            <div className='album-content-user-name'>
                                {album.channel.title}
                            </div>
                            <TubeSubmit
                                onClick={() => {}}
                                label="Subscribe"             
                            />
                        </div>
                    </>
                )}  
                {(((!album.channel || album.channel.show_user !== "2") && moduleUser) && showMore) && (
                    <>
                        <div className="line"/>
                        <div className='album-content-user' onClick={() => navigate(`/profile/${album.user_id}`)}>
                            <div className='album-content-user-avatar' style={{backgroundImage: `url("${appUrl}/${album.userAvatar}")`}}/>
                            <div className='album-content-user-name'>
                                {album.user}
                            </div>
                            <TubeSubmit
                                onClick={() => {}}
                                label="Subscribe"             
                            />
                        </div>
                    </>
                )}
                {(album.models && album.models.length > 0 && showMore) && (
                    <>
                        <div className="line"/>
                        <div className='album-content-models'>
                            <h2>Models</h2>
                            {album.models.map(model => (
                                <>
                                    <div className='album-content-model-avatar' style={{backgroundImage: `url("${model.avatar}")`}} onClick={() => navigate(`/model/${model.title_slug}`)}/>
                                    <div className='album-content-model-name' onClick={() => navigate(`/model/${model.title_slug}`)}>
                                        {model.name}
                                    </div>
                                </>
                            ))}
                        </div>
                    </>
                )}
                {(album.categories && album.categories.length > 0 && showMore) && (
                    <>
                        <div className="line"/>
                        <div className='album-content-categories'>
                            <h2>Categories</h2>
                            <div className="categories-content-tags">
                                {album.categories && album.categories.map(category => (
                                    <TubeButton
                                        label={category.name}
                                        onClick={() => navigate(`/videos/${category.title_slug}`)}
                                    />
                                ))}
                            </div>
                        </div>
                    </>
                )}
                {(album.tags && album.tags.length > 0 && showMore) && (
                    <>
                        <div className="line"/>
                        <div className='album-content-categories'>
                            <h2>Tags</h2>
                            <div className="categories-content-tags">
                                {album.tags && album.tags.map(tag => (
                                    <TubeButton
                                        label={tag.name}
                                        onClick={() => navigate(`/videos/tag/${tag.title_slug}`)}
                                    />
                                ))}
                            </div>
                        </div>
                    </>
                )}
                <div className="line"/>
                <TubeSubmit
                    onClick={() => {setShowMore(prevState => !prevState)}}
                    label={showMore ? "Show less" : "Show more"}    
                    className={"show-more"}         
                />
                <div className="line"/>
            </div>
            <Sidebar videos width={350} />
        </div>
    );
}

export default Album;

Album.propTypes = {
    id: PropTypes.number
};