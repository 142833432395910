import PropTypes from 'prop-types';
import LoadingButton from '@mui/lab/LoadingButton';
import LastPageSharpIcon from '@mui/icons-material/LastPageSharp';
import AddIcon from '@mui/icons-material/Add';
import GbFlag from "../../assets/gb.svg";
import './TubeSubmit.css';
import { ArrowBack, Close, CloseOutlined, FavoriteBorderOutlined, FavoriteOutlined, FlagOutlined, LoginOutlined, Person3Outlined, PersonOutline, ShareOutlined, ThumbUpAltOutlined } from '@mui/icons-material';

function TubeSubmit(props) {
    const { sx, isLoading, onClick, label, variant, className, redirectTo, icon } = props;

    function handleRedirect() {
        window.location.href = "/frontend"+redirectTo;
    }

    function getIcon(icon){
        switch(icon){
            case "download": 
                return (
                    <LastPageSharpIcon className="tube-submit-button-icon r90" sx={{marginLeft: "-5px"}}/>
                );
            case "add": 
                return (
                    <AddIcon className="tube-submit-button-icon r90" sx={{marginLeft: "-5px"}}/>
                );
            case "user": 
                return (
                    <PersonOutline className="tube-submit-button-icon" sx={{marginLeft: "-5px"}}/>
                );
            case "signup": 
                return (
                    <LoginOutlined className="tube-submit-button-icon" sx={{marginLeft: "-5px"}}/>
                );
            case "gbFlag": 
                return (
                    <img src={GbFlag} />
                );
            case "back": 
                return (
                    <ArrowBack className="tube-submit-button-icon" sx={{marginLeft: "-5px", fontSize: "18px"}}/>
                );
            case "like": 
                return (
                    <ThumbUpAltOutlined className="tube-submit-button-icon" sx={{marginLeft: "-5px", fontSize: "16px"}}/>
                );
            case "dislike": 
                return (
                    <ThumbUpAltOutlined className="tube-submit-button-icon" sx={{marginLeft: "-5px", fontSize: "16px", transform: "rotate(180deg)"}}/>
                );
            case "favorite": 
                return (
                    <FavoriteBorderOutlined className="tube-submit-button-icon" sx={{marginLeft: "-5px", fontSize: "16px"}}/>
                );
            case "share": 
                return (
                    <ShareOutlined className="tube-submit-button-icon" sx={{marginLeft: "-5px", fontSize: "16px"}}/>
                );
            case "flag": 
                return (
                    <FlagOutlined className="tube-submit-button-icon" sx={{marginLeft: "-5px", fontSize: "16px"}}/>
                );
            case "close": 
                return (
                    <Close className="tube-submit-button-icon" sx={{marginLeft: "-5px", fontSize: "16px"}}/>
                );
        }
    }

    return (
        <LoadingButton 
            variant="contained" 
            className={`confirm-button ${variant ? variant : ''} ${className}`}
            loading={isLoading}
            onClick={redirectTo ? handleRedirect : onClick}
            component="label"
            sx={sx}
        >
            {icon ? getIcon(icon) : null}
            {label}
        </LoadingButton>
    );
}

export default TubeSubmit;

TubeSubmit.propTypes = {
    sx: PropTypes.object,
    isLoading: PropTypes.bool,
    onClick: PropTypes.func,
    label: PropTypes.any,
    variant: PropTypes.string,
    className: PropTypes.string,
    redirectTo: PropTypes. string
};