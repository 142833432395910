import NavigationLink from "../navigation/NavigationLink";
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import { CameraAltOutlined, ChatOutlined, FavoriteBorderOutlined, VideocamOutlined, VisibilityOutlined } from "@mui/icons-material";
import TubeSubmit from "../form/TubeSubmit";
import { NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import { appUrl, formatNumber, get } from "../../utils/fetch";
import { moduleUser } from "../../utils/modules";

export default function Sidebar(props){
    const { models, videos, width, ad } = props;
    const iconStyle = {
        fontSize: 24,
        marginRight: 1,
        color: "inherit"
    }

    const [ sidebar, setSidebar ] = useState({});
    const [ moreCategoriesOpen, setMoreCategoriesOpen ] = useState(false); 

    useEffect(() => {
        get(`${appUrl}/api/sidebar`, (result) => {setSidebar(result.data)});
    }, []);

    return (
        <div className="categories-sidebar" style={{
            width: width ? width : 230
        }}>
            {(ad && ad.length > 0) && ad.map(sidebarAd => (
                <div className="tube-player-ad-4" dangerouslySetInnerHTML={{__html: sidebarAd.source}} />
            ))}
            {models ? (
                <>
                    <h2>Top models</h2>
                    <div className="categories-sidebar-profiles">
                        {sidebar.models && sidebar.models.map(model => (
                            <NavLink to="" key={model.id}>
                                <div className="categories-sidebar-profile" style={{backgroundImage: `url("${appUrl}/avatars/${model.thumbnail}")`}} />
                                <div className="categories-sidebar-profile-name">{model.stage_name}</div>
                            </NavLink>
                        ))}
                    </div>
                    <div className="line"/>
                </>
            ) : null}
            {videos ? ( 
                <>
                    <h2 className={(ad && ad.length > 0) ? "mt-30" : ""}>Top categories</h2>
                    {sidebar.categories && sidebar.categories.map(category => (
                        <NavigationLink
                            key={category.id}
                            variant="plain"
                            src={`/videos/${category.title_slug}`}
                            linkName={category.name}
                            total={category.videos_count}
                        />
                    ))}
                    {(moreCategoriesOpen && sidebar.categoriesMore) && sidebar.categoriesMore.map(category => (
                        <NavigationLink
                            variant="plain"
                            src={`/videos/${category.title_slug}`}
                            linkName={category.name}
                            total={category.videos_count}
                            key={category.id}
                        />
                    ))}
                    <TubeSubmit
                        onClick={() => setMoreCategoriesOpen(!moreCategoriesOpen)}
                        label={moreCategoriesOpen ? "Show less" : "Load more"}
                        variant="plainOutlined"                
                    />
                    <div className="line"/>
                    <h2>Top channels</h2>
                    {sidebar.channels && sidebar.channels.map(channel => (
                        <NavLink to={`/channel/${channel.title_slug}`} key={channel.id}>
                            <div className="categories-sidebar-channel" style={{backgroundImage: `url("${appUrl}/${channel.cover}")`}}>
                                <div className="categories-sidebar-channel-user" style={{backgroundImage: `url("${appUrl}/${channel.logo}")`}}></div>
                                {moduleUser && (
                                    <div className="categories-sidebar-channel-button-wrapper">
                                        <TubeSubmit
                                            label="+"
                                            variant={"outlined"}
                                        />
                                    </div>
                                )}
                            </div>
                            <div className="categories-sidebar-channel-name">{channel.title}</div>
                            <div className='categories-content-channels-info'>
                                <span><VideocamOutlined /> {formatNumber(channel.videos)}</span>
                                <span><VisibilityOutlined /> {formatNumber(channel.videoViews)}</span>
                                {moduleUser && (
                                    <>
                                        <span><FavoriteBorderOutlined /> 0</span>
                                        <span><ChatOutlined /> 0</span>
                                    </>
                                )}
                            </div> 
                        </NavLink>
                    ))}
                    {moduleUser && (
                        <>
                            <div className="line"/>
                            <h2>Top profiles</h2>
                            <div className="categories-sidebar-profiles">
                                {sidebar.users && sidebar.users.map(user => (
                                    <NavLink to={`/profile/${user.id}`} key={user.id}>
                                        <div className="categories-sidebar-profile" style={{backgroundImage: `url("${appUrl}/avatars/${user.avatar}")`}} />
                                        <div className="categories-sidebar-profile-name">
                                            {user.display_name}
                                            <div className='categories-content-channels-info'>
                                                <span><VisibilityOutlined /> {user.views ? formatNumber(user.views) : 0}</span>
                                                <span><FavoriteBorderOutlined /> 0</span>
                                                <span><ChatOutlined /> 0</span>
                                            </div> 
                                        </div>
                                    </NavLink>
                                ))}
                            </div>
                        </>
                    )}
                </>
            ) : null}
        </div>
    );
}