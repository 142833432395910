import { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { appUrl, get } from "../../utils/fetch";
import "./Search.css";
import TubeSubmit from "../form/TubeSubmit";
import VideoList from "../dataTable/VideoList";
import AlbumList from "../dataTable/AlbumList";
import ModelList from "../dataTable/ModelList";
import ChannelList from "../dataTable/ChannelList";
import { useDispatch, useSelector } from "react-redux";
import { edit as editHeader } from '../../slices/headerSlice';

export default function Home(props){
    const { search } = useParams();
    const settings = useSelector(state => state.settings);
    const dispatch = useDispatch();

    const [ searchData, setSearchData ] = useState({
        videos: {data: [], total: 0},
        albums: {data: [], total: 0},
        models: {data: [], total: 0},
        categories: {data: [], total: 0},
        channels: {data: [], total: 0}
    });

    useEffect(() => {
        get(`${appUrl}/api/search?name=${search}&order=asc`, (result) => {
            setSearchData(result.data);
        });
        dispatch(editHeader({title: `Search result for "${search}" - ${settings.siteTitle}`}));
    }, [search]);

    return (
        <div className="home-wrapper">
            <div className="search-content">
                <h1>Search result for: {search}</h1>
                <h2 style={{marginTop: 30}}>Most relevant videos <span class="navigation-link-total">{searchData && searchData.videos.total}</span></h2>
                <div className="categories-content-videos">
                    <VideoList data={searchData.videos.data} />
                    {(searchData && searchData.videos.total > 7) && (
                        <NavLink to={`/videos/search/${search}`}>
                            <div className="categories-content-videos-video show-more">
                                <div>More videos <span class="navigation-link-total">{searchData && searchData.videos.total}</span></div>
                                <TubeSubmit
                                    onClick={() => {}}
                                    label="All videos"               
                                />
                            </div>
                        </NavLink>
                    )}
                </div>
                <h2>Most relevant galleries <span class="navigation-link-total">{searchData && searchData.albums.total}</span></h2>
                <div className="categories-content-albums">
                    <AlbumList data={searchData.albums.data} />
                    {(searchData && searchData.albums.total > 6) && (
                        <NavLink to={`/galleries/search/${search}`}>
                            <div className="categories-content-albums-album show-more">
                                <div>More galleries <span class="navigation-link-total">{searchData && searchData.albums.total}</span></div>
                                <TubeSubmit
                                    onClick={() => {}}
                                    label="All galleries"                
                                />
                            </div>
                        </NavLink>
                    )}
                </div>
                <h2>Most relevant models <span class="navigation-link-total">{searchData && searchData.models.total}</span></h2>
                <div className="categories-content-models">
                    <ModelList data={searchData.models.data} />        
                    {(searchData && searchData.models.total > 6) && (
                        <NavLink to={`/models/search/${search}`}>
                            <div className="categories-content-albums-album show-more">
                                <div>More models <span class="navigation-link-total">{searchData && searchData.models.total}</span></div>
                                <TubeSubmit
                                    onClick={() => {}}
                                    label="All models"              
                                />
                            </div>
                        </NavLink>
                    )}
                </div>
                <h2>Most relevant channels <span class="navigation-link-total">{searchData && searchData.channels.total}</span></h2>
                <div className="categories-content-models">
                    <ChannelList data={searchData.channels.data} />
                    {(searchData && searchData.channels.total > 6) && (
                        <NavLink to={`/channels/search/${search}`}>
                            <div className="categories-content-albums-album show-more search-channel">
                                <div>More channels <span class="navigation-link-total">{searchData && searchData.channels.total}</span></div>
                                <TubeSubmit
                                    onClick={() => {}}
                                    label="All channels"              
                                />
                            </div>
                        </NavLink>
                    )}
                </div>
                <h2>Most relevant categories</h2>
                <div className="categorylist-content-list">
                    {searchData && searchData.categories.data.map(category => (
                        <NavLink to={`/videos/${category.title_slug}`} className="categorylist-content-category">
                            <div className="categorylist-category-thumbnail" style={{backgroundImage: `url("${appUrl}/category-thumbnails/${category.thumbnail}")`}}/>
                            <span>{category.name}</span>
                        </NavLink>
                    ))}
                </div>
            </div>
        </div>
    );
}