
import "./Tags.css";
import TubeSubmit from "../form/TubeSubmit";
import { Link, NavLink, useParams } from "react-router-dom";
import TubeButton from "../form/TubeChip";
import Sidebar from "./Sidebar";
import { useEffect, useState } from "react";
import { appUrl, get } from "../../utils/fetch";
import DataTablePagination from "../dataTable/DataTablePagination";
import TextInput from "../form/TextInput";
import { edit as editHeader } from '../../slices/headerSlice';
import { useDispatch, useSelector } from "react-redux";

export default function Tags(props){
    const { page, letter } = useParams();
    const [ tags, setTags ] = useState([]);
    const [ totalPages, setTotalPages ] = useState(1);
    const [ search, setSearch ] = useState(null);

    const settings = useSelector(state => state.settings);
    const dispatch = useDispatch();

    const getTags = () => {
        get(`${appUrl}/api/content-tags?letter=${letter ? letter : "All"}&page=${page ? page : "1"}&search=${search ? search : ""}`, (result) => {
            setTags(result.data.data);
            setTotalPages(result.data.last_page);
        });
        dispatch(editHeader({title: `Tags - ${settings.siteTitle}`}));
    }

    useEffect(() => {
        getTags();
    }, [letter, page]);

    useEffect(() => {
        const timer = setTimeout(() => {
            getTags();
        }, 500);
        return () => clearTimeout(timer);
    }, [search]);

    const alphabet = ["All", "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];
    
    return (
        <div className="tags-wrapper">
            <div className="taglist-content">
                <h1>Tags</h1>
                <div className="taglist-pagination">
                    {alphabet.map(item => (
                        <Link
                            to={`/tags/${item}/page/1`}
                            className={`
                                ${((item === letter) || (!letter && item === "All")) ? "active" : ""}
                            `}
                        >
                            {item}   
                        </Link>
                    ))}
                </div>
                <div className="taglist-content-list">
                    <TextInput
                        noRedux
                        noReduxValue={search}
                        noReduxOnChange={setSearch}
                        placeholder={"Search tags..."}
                    />
                    {tags && tags.map(tag => {
                        if(tag.name){
                            return (
                                <Link to={`/videos/tag/${tag.title_slug}/page/1`} className="taglist-content-tag">
                                    <span>{tag.name}</span>
                                    <div className="tag-total-videos">{tag.total}</div>
                                </Link>
                            )
                        }
                    })}
                </div>
                <div className="categories-content-videos-pagination">
                    <DataTablePagination
                        page={page ? page : 1}
                        url={`/tags/${letter || 'All'}`}
                        totalPages={totalPages}
                    />
                </div>
            </div>     
        </div>
    );
}