import { NavLink } from "react-router-dom";
import { appUrl, formatNumber } from "../../utils/fetch";
import { ChatOutlined, FavoriteBorderOutlined, VisibilityOutlined } from "@mui/icons-material";
import { moduleUser } from "../../utils/modules";
import { useEffect, useState } from "react";
import "./VideoList.css";

export default function VideoList(props){
    const { data, limit } = props;

    const [videos, setVideos] = useState([]);
    
    useEffect(() => {
        if(data){
            if(limit){
                setVideos(data.slice(0, limit))
            }
            else {
                setVideos(data);
            }
        }
    }, [data, limit]);

    return (
        <>
            {videos && videos.map(video => (
                <>
                    {video?.ad ? (
                        <div className="ad-wrapper">
                            <div className="categories-content-videos-video" style={{backgroundImage: `url(${appUrl}/${video.thumb})`}}>
                                <div className="tube-player-ad-3" dangerouslySetInnerHTML={{__html: video.source}} />
                                <div className='categories-content-channels-info'>
                                    <span><VisibilityOutlined /> {formatNumber(video.views)}</span>
                                </div> 
                            </div>
                            <div className="categories-content-videos-name">{video.title ? video.title : video.name}</div>
                        </div>
                    ) : (
                        <NavLink to={!video.ad ? `/video/${video.title_slug}` : "#"} key={video.id}>
                            <div className={`categories-content-videos-video ${video.vertical ? 'vertical' : ''}`} style={{backgroundImage: `url(${appUrl}/${video.thumb})`}}>
                                {video.thumb === "no-preview" ? (
                                    <div className="no-preview-text">No preview</div>
                                ) : (
                                    <video src={`${appUrl}/${video.thumb.replace("jpg", "mp4").replace("thumbs/", "thumbs/preview-")}`} autoPlay loop muted/>
                                )}
                                <div className="categories-content-videos-video-length">
                                    {video.duration}
                                </div>
                                <div className='categories-content-channels-info'>
                                    <span><VisibilityOutlined /> {formatNumber(video.views)}</span>
                                    {moduleUser && (
                                        <>
                                            <span><FavoriteBorderOutlined /> 0</span>
                                            <span><ChatOutlined /> 0</span>
                                        </>
                                    )}
                                </div> 
                            </div>
                            {(moduleUser && !video.title) && (
                                <>
                                    <div className="categories-content-videos-user-wrapper">
                                        <div className="categories-content-videos-user" style={{backgroundImage: `url(${appUrl}/${video.userAvatar})`}}/>
                                        <div className="categories-content-videos-username">{video.user}</div>
                                    </div>
                                </>
                            )}
                            <div className="categories-content-videos-name">{video.title ? video.title : video.name}</div>
                        </NavLink>
                    )}
                </>
            ))}
        </>
    ); 
}