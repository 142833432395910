import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import "./Header.css";
import TextInput from '../form/TextInput';
import TubeSubmit from '../form/TubeSubmit';
import { useNavigate, useParams } from 'react-router';
import { moduleUser } from '../../utils/modules';
import { appUrl } from '../../utils/fetch';
import { useDispatch, useSelector } from 'react-redux';
import { edit as editSearch } from "../../slices/searchSlice";
import { Menu, Search } from '@mui/icons-material';
import { edit as editHeader } from '../../slices/headerSlice';

export default function Header(props) {
    const { search } = useParams();
    
    const searchQuery = useSelector(state => state.search.query);
    const openMenu = useSelector(state => state.header.openMenu);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if(searchQuery){
            const timer = setTimeout(() => {
                navigate(`/search/${searchQuery}`);
            }, 2000);
            return () => clearTimeout(timer);
        }
    }, [searchQuery]);

    useEffect(() => {
        if(!search){
            dispatch(editSearch({query: ""}));
        }
        else {
            dispatch(editSearch({query: search}));
        }
    }, [ search ]);

    const toggleMobileNavigation = () => {
        dispatch(editHeader({openMenu: !openMenu}))
    }

    return (
        <React.Fragment>
            <div className="header">
                <div>
                    <Link to="/">
                        <img src={`${appUrl}/assets/file_header_logo.png`} alt="Nakedmilfs" style={{
                            width: "190px",
                            padding: "15px 20px"
                        }}/>
                    </Link>
                    <div className='search-wrapper'>
                        <TextInput
                            className="header-search"
                            stateName={"search"}
                            valueName={"query"}
                            placeholder="Search..."
                            icon={"search"}
                            dispatchFunc={editSearch}
                            adornmentIcon={<Search />}
                            adornmentLabel="Search"
                            adornmentOnClick={() => {}}
                        />
                        <Menu
                            className='mobile-navigation-button'
                            onClick={toggleMobileNavigation}
                        />
                    </div>
                    {/* <div className='user-menu'>
                        {moduleUser && (
                            <>
                                <TubeSubmit
                                    label="Login"
                                    redirectTo="/login"
                                    icon="user"
                                    variant={"plain"}
                                />
                                <TubeSubmit
                                    label="Sign up"
                                    redirectTo="/sign-up"
                                    icon="signup"
                                    variant={"plain"}
                                />
                            </>
                        )}
                        <TubeSubmit
                            label={"EN"}
                            redirectTo="/"
                            icon="gbFlag"
                            variant={"plain"}
                        />
                    </div> */}
                </div>
            </div>
        </React.Fragment>
    )
}

Header.propTypes = {
    title: PropTypes.string
};