import { CameraAltOutlined, ChatOutlined, PersonAddAlt, VideocamOutlined, VisibilityOutlined } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { appUrl, formatNumber, get } from '../../utils/fetch';
import TubeButton from '../form/TubeChip';
import TubeSubmit from '../form/TubeSubmit';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import "./Model.css";
import Tabs from '../navigation/Tabs';
import { SocialIcon } from 'react-social-icons';
import { moduleUser } from '../../utils/modules';
import VideoList from '../dataTable/VideoList';
import AlbumList from '../dataTable/AlbumList';
import { edit as editHeader } from '../../slices/headerSlice';
import { useDispatch, useSelector } from "react-redux";
import DataTablePagination from '../dataTable/DataTablePagination';
import ModelList from '../dataTable/ModelList';
import { ReactComponent as OnlyFansLogo } from '../../assets/of-logo.svg';
import { ReactComponent as AmazonLogo } from '../../assets/amazon-logo.svg';

function Model(props) {
    const { id, subTab, page } = useParams();
    const navigate = useNavigate();
    
    const [ units, setUnits ] = useState(["kg", "cm", "cm"]);
    const [ model, setModel ] = useState({
        id: 0,
        models: [],
        logo: '',
        relatedModels: [],
        featuredVideos: []
    });

    const [ videos, setVideos ] = useState([]);
    const [ albums, setAlbums ] = useState([]);
    const [ flagImage, setFlagImage ] = useState(null);
    const [ totalPages, setTotalPages ] = useState(1);

    const dispatch = useDispatch();
    const settings = useSelector((state) => state.settings);

    useEffect(() => {
        get(`${appUrl}/api/model/${id}`, (result) => {
            if (result.response?.status === 404) {
                navigate('/404');
                return;
            }
            setModel(result.data);
            dispatch(editHeader({title: `${result.data.stage_name} - ${settings.siteTitle}`}));
            try {
                setFlagImage(require(`../../assets/flags/4x3/${result.data?.country && result.data?.country[0] && result.data?.country[0].code.toLowerCase()}.svg`));
            } catch (error) {}    
        });
    }, [page, id]);

    useEffect(() => {
        if(model?.id > 0){
            get(`${appUrl}/api/videos?limit=8&status=1&order=desc&column=id&model_id=${model?.id}&page=${page}`, (result) => {
                setVideos(result.data?.data);
                setTotalPages(result.data?.last_page);
            });
        }
        get(`${appUrl}/api/albums?limit=6&order=desc&column=id&model_id=${model?.id}`, (result) => {setAlbums(result.data?.data)});
        window.scrollTo({
            top: 0
        });
    }, [model, page])

    useEffect(() => {
        if(settings?.units === "imperial"){
            setUnits(["lb", "in", ""]);
        }
    } ,[settings]);

    const tabsData = [
        { 
            title: "About",
            href: `/model/${id}`,
            icon: <FavoriteBorderIcon />,
            active: !subTab,
            content: (
                <div className='model-content-about'>
                    <div className='model-content-about-titles'>
                        <h2>About {model?.name}</h2>
                    </div>
                    <div className='about-me'>
                        {model?.biography}
                    </div>
                    <div className='model-content-about-stats'>
                        <div>Relation status</div>
                        <div><span>{model?.relationshipText}</span></div>
                        <div>Orientation</div>
                        <div><span>{model?.orientationText}</span></div>
                        <div>Career status</div>
                        <div><span>{model?.careerStatusText}</span></div>
                        <div>Height</div>
                        <div><span>{model?.heightText} {model?.heightText > 0 && units[2]}</span></div>
                        <div>Weight</div>
                        <div><span>{model?.weightText} {model?.weightText > 0 && units[0]}</span></div>
                        <div>Hair color</div>
                        <div><span>{model?.hairColorText}</span></div>
                        <div>Eye color</div>
                        <div><span>{model?.eye_colorText}</span></div>
                    </div>
                    <div className='model-content-about-stats'>
                        <div>Ethnicity</div>
                        <div><span>{model?.ethnicityText}</span></div>
                        <div>Gender</div>
                        <div><span>{model?.genderText}</span></div>
                        <div>Measurements ({units[1]})</div>
                        <div><span>{model?.measurementsText}</span></div>
                        <div>Career start and end</div>
                        <div>
                            <span>
                                {(model?.career_start && model?.career_start !== "") ? (
                                    <>
                                        {model?.career_start} - {
                                            model?.career_end && model?.career_end !== "" ?  model?.career_end : "now" 
                                        }
                                    </>
                                ) : (
                                    <>
                                        N/A
                                    </>
                                )}
                            </span>
                        </div>
                        <div>Tattoos</div>
                        <div><span>{model?.tattoosText}</span></div>
                        <div>Piercing</div>
                        <div><span>{model?.piercingsText}</span></div>
                        <div>Interests</div>
                        <div><span>{model?.interestsText}</span></div>
                    </div>
                    <div className='model-detail-featured-videos'>
                        <h2>{model?.name}'s videos</h2>
                        <VideoList data={model?.featuredVideos} limit={10}/>
                        <h2 className='related-models-title'>Related models</h2>
                        <div className='model-detail-related-models'>
                            <ModelList data={model?.relatedModels || []} limit={7}/>
                        </div>
                    </div>
                </div>
            )
        },
        { 
            title: "Videos",
            href: `/model/${id}/videos`,
            icon: <VideocamOutlined />,
            active: subTab === "videos",
            content: (
                <>
                    <div className='model-content-about-titles'>
                        <h2>{model?.name}'s videos</h2>
                    </div>
                    <div className="categories-content-videos">
                        <VideoList data={videos} />
                    </div>
                    <div className="categories-content-videos-pagination">
                        <DataTablePagination
                            page={page ? page : 1}
                            url={`/model/${id}/videos/`}
                            totalPages={totalPages}
                        />
                    </div>
                </>
            )
        },
        { 
            title: "Galleries",
            href: `/model/${id}/galleries`,
            icon: <CameraAltOutlined />,
            active: subTab === "galleries",
            content: (
                <>
                    <div className='model-content-about-titles'>
                        <h2>{model?.name}'s galleries</h2>
                    </div>
                    <div className="categories-content-albums">
                        <AlbumList data={albums} />
                    </div>
                </>
            )
        }
    ];

    if (moduleUser) {
        tabsData.push({
            title: "Friends",
            href: `/model/${id}/friends`,
            icon: <PersonAddAlt />,
            active: subTab === "friends",
            content: (
                <>
                    <div className='model-content-about-titles'>
                        <h2>{model?.name}'s friends</h2>
                    </div>
                </>
            )
        });
    }

    return (
        <div className='model-content'>
            <div className='model-content-header'>
                <div className='model-content-header-background' style={{backgroundImage: `url("${appUrl}/model-covers/${model?.cover}")`}} />
                <div className='model-content-header-info'>
                    <div>
                        <div className='model-content-header-logo'  style={{backgroundImage: `url("${appUrl}/avatars/${model?.thumbnail}")`}}>
                            {flagImage && (
                                <img src={flagImage} className='model-flag' alt='Flag' />
                            )}
                        </div>
                        <div className='model-content-header-rc'>
                            <h1>{model?.stage_name}</h1>
                            <div className='model-content-header-shortdesc'>
                                {model?.short_description}
                            </div>
                            <div className='model-content-header-stats'>
                                <TubeButton
                                    label={(
                                        <>
                                            <VideocamOutlined /> {formatNumber(model?.videos)}
                                            <VisibilityOutlined /> {formatNumber(model?.videoViews)}
                                        </>
                                    )}
                                />
                                <TubeButton
                                    label={(
                                        <>
                                            <CameraAltOutlined /> {formatNumber(model?.albums)}
                                            <VisibilityOutlined /> {formatNumber(model?.albumViews)}
                                        </>
                                    )}
                                />
                                {moduleUser && (
                                    <>
                                        <TubeButton
                                            label={(
                                                <>
                                                    <PersonAddAlt /> 0
                                                </>
                                            )}
                                        />
                                        <TubeButton
                                            label={(
                                                <>
                                                    <ChatOutlined /> 0
                                                </>
                                            )}
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                        <div>
                            <div className="model-social">
                                {model?.onlyfans && (
                                    <a href={model?.twitter} class="social-icon custom-social-icon" aria-label="onlyfans">
                                        <span class="social-container">
                                            <OnlyFansLogo />    
                                        </span>
                                    </a>
                                )}
                                {model?.twitter && (<SocialIcon network="twitter" url={model?.twitter} fgColor={settings.themeColorWhite || "#f84"} bgColor={settings.themeColorButton || "rgb(255, 255, 255)"} />)}
                                {model?.reddit && (<SocialIcon network="reddit" url={model?.reddit} fgColor={settings.themeColorWhite || "#f84"} bgColor={settings.themeColorButton || "rgb(255, 255, 255)"} />)}
                                {model?.instagram && (<SocialIcon network="instagram" url={model?.instagram} fgColor={settings.themeColorWhite || "#f84"} bgColor={settings.themeColorButton || "rgb(255, 255, 255)"} />)}
                                {model?.facebook && (<SocialIcon network="facebook" url={model?.facebook} fgColor={settings.themeColorWhite || "#f84"} bgColor={settings.themeColorButton || "rgb(255, 255, 255)"} />)}
                                {model?.amazon && (
                                    <a href={model?.amazon} class="social-icon custom-social-icon" aria-label="amazon">
                                        <span class="social-container">
                                            <AmazonLogo />    
                                        </span>
                                    </a>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='model-content-subscribe'>
                <div className='model-content-subscribe-lc'>
                    {moduleUser && (
                        <>
                            <TubeSubmit
                                onClick={() => {}}
                                label="0"
                                icon="favorite"
                                variant="plainOutlined"                
                            />
                        </>
                    )}
                    {moduleUser && (
                        <>
                            <TubeSubmit
                                onClick={() => {}}
                                label="Block model"
                                icon="close"
                                variant="plainOutlined"                
                            />
                        </>
                    )}
                </div>
                <div className='model-content-subscribe-rc'>
                    {moduleUser && (
                        <>
                            <TubeSubmit
                                onClick={() => {}}
                                label="Subscribe"          
                            />
                            <TubeSubmit
                                onClick={() => {}}
                                label="Join now"
                                variant={"outlined"}          
                            />
                        </>
                    )}
                </div>
            </div>
            <div className='line' />
            <Tabs
                title=""
                tabs={tabsData}
            />
        </div>
    );
}

export default Model;