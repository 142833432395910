import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import Form from '../form/Form';
import TubeSubmit from '../form/TubeSubmit';
import "./Dialog.css";

function DialogComponent(props) {
    const { onClose, open, title, content, buttons, isLoading, filter, filterHeader, filterColumns, stateName, filterDispatchFunc, form, className, maxWidth, disableScrollLock, disableEnforceFocus } = props;
    const [ filterInputs, setFilterInputs ] = React.useState({});

    const handleClose = () => {
        onClose();
    };

    React.useEffect(() => {
        if(filterHeader){
            let availableFilterInputs = {};
            Object.keys(filterHeader).forEach(key => {
                if(filterHeader[key].field ==="text" || filterHeader[key].field ==="filter"){
                    availableFilterInputs = {...availableFilterInputs, [key]: filterHeader[key] };
                }
            });
            setFilterInputs(availableFilterInputs);
        }
    }, [filterHeader]);
    

  return (
    <Dialog
        onClose={handleClose}
        open={open}
        maxWidth={false}
        className={className ? className : ""} 
        scroll={"body"}
        style={maxWidth ? {
            maxWidth: maxWidth,
            left: "50%",
            marginLeft: -(maxWidth/2)
        } : null}
        disableScrollLock={disableScrollLock}
        disableEnforceFocus={disableEnforceFocus}
    >
        {title ? <DialogTitle>{title}</DialogTitle> : null}
        <DialogContent>
                <div style={{
                    display: isLoading === 2 ? "none" : "block"
                }}>
                    {content}   
                    {filter ? (
                        <Form
                            inputs={filterInputs}
                            columns={filterColumns}
                            stateName={stateName}
                            dispatchFunc={filterDispatchFunc}
                            disabledDefault
                        />
                    ) : null}
                    {buttons && (!isLoading || isLoading === 1) ? (
                        <Stack 
                            spacing={1} 
                            direction="row"
                            sx={{
                                paddingTop: 3
                            }}
                            className={form ? "dialog-form-buttons-stack" : ""}
                        >
                            {buttons.map((button, key) => (
                                <TubeSubmit key={key} variant={button.variant} onClick={button.onClick} disabled={button.disabled} label={button.label} />
                            ))}
                        </Stack>
                    ) : null}
                </div>
                <div style={{
                    display: !isLoading || isLoading === 1 ? "none" : "block"
                }}>
                    <CircularProgress />
                </div>
        </DialogContent>
    </Dialog>
  );
}

DialogComponent.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    title: PropTypes.string,
    content: PropTypes.any,
    buttons: PropTypes.array,
    isLoading: PropTypes.bool,
    filter: PropTypes.bool,
    filterHeader: PropTypes.object,
    filterColumns: PropTypes.number,
    stateName: PropTypes.string,
    filterDispatchFunc: PropTypes.func,
    form: PropTypes.bool,
    className: PropTypes.string,
    maxWidth: PropTypes.number
};

export default DialogComponent;