import * as React from 'react';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TubeChip from './TubeChip';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import "./MultiSelect.css";
import TubeSubmit from './TubeSubmit';
import DialogComponent from '../dialog/Dialog';
import TextInput from './TextInput';
import { appUrl, get } from '../../utils/fetch';
import { Rectangle } from '@mui/icons-material';
import CheckOutsideRefWrapper from '../wrappers/CheckOutsideRefWrapper';

export default function MultiSelect(props) {
  const { label, stateName, valueName, dispatchFunc, data, disabled, sx, extraFuncType, extraFuncName, extraFuncLabel, disableMultiple, loadEmpty, className } = props;
  const value = useSelector((state) => state[stateName][valueName]);
  const meta = useSelector((state) => state[stateName]["meta"]);
  const dispatch = useDispatch();

  const [ search, setSearch ] = React.useState("");
  const [ searchData, setSearchData ] = React.useState([]);
  const [ isLoadingSearch, setIsLoadingSearch ] = React.useState(true);

  const [ extraFuncDialog, setExtraFuncDialog ] = React.useState({
    name: "",
    isOpen: false
  })

  const handleChange = (item) => {
    let newValue = value;
    newValue = [ ...newValue, item ];
    dispatch(dispatchFunc({[valueName]: disableMultiple ? [item] : newValue}));
  };

  const handleTriggerSearch = React.useCallback(() => {
    let ids = value.map(item => { return item.id }).join(",");
    get(`${appUrl}/api/${data}?q=${search}&selected=${ids}`, (e) => {
      setSearchData(e.data);
      setIsLoadingSearch(false);
    }, () => setIsLoadingSearch(true));
  }, [value, search, data]);

  let typingTimeout;

  React.useEffect(() => {
    if(search){
      clearTimeout(typingTimeout);
      typingTimeout = setTimeout(() => {
        handleTriggerSearch();
      }, 1000);
      return () => {
        clearTimeout(typingTimeout);
      };
    }
  }, [search, handleTriggerSearch]);

  const handleExtraFunc = () => {
    if(extraFuncType){
      handleChange({ id: `0${extraFuncDialog.name}`, name: extraFuncDialog.name });
      setExtraFuncDialog(prevState => ({...prevState, isOpen: false}));
    }
  }

  const handleRemoveItem = (itemId) => {
    const tempValue = value.filter(itemObject => itemObject.id !== itemId);
    dispatch(dispatchFunc({[valueName]: tempValue}));
  }

  const handleSelectItem = (item) => {
    handleChange(item);
    setIsLoadingSearch(true);
    //setSearch("");
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleChange({ id: `0${search}`, name: search });
      setSearch("");
    }
  };

  return (
    <div className="tube-multiselect-component" style={!extraFuncType ? {paddingTop: 0, border: 0, ...sx} : sx}>
      <div className="tube-multiselect-label">{label}</div>
      <CheckOutsideRefWrapper
        children={(
          <>
            <TextInput
              label={typeof data === "string" ? "Type here..." : "Select one..."}
              valueName="search"
              noRedux
              noReduxOnChange={(e) => setSearch(e)}
              noReduxValue={search}
              onKeyDown={handleKeyPress}
              onClick={typeof data === "string" ? null : () => {setIsLoadingSearch(false); setSearchData(data)}}
            />
            {((search) || (typeof data !== "string" && !isLoadingSearch && searchData.length !== value.length)) && (isLoadingSearch || searchData.length > 0) ? (
              <div className={'tube-multiselect-list'}>
                {isLoadingSearch ? (
                  <div className='tube-multiselect-list-searching'>Searching...</div>
                ) : (
                  <>
                    {searchData.map(item => {
                      if(value.find(obj => obj.id === item.id) === undefined){
                        return (
                          <div className='tube-multiselect-list-item' onClick={() => {handleSelectItem(item)}}>{item.name}</div>
                        );
                      }
                    })}
                  </>
                )}
                
              </div>
            ) : null}
          </>
        )}
        func={() => setSearch("")}
      />
      <div className={`tube-multiselect-labels-container ${className}`}>
        {value.map(item => (
          <TubeChip label={item.name} onClick={() => handleRemoveItem(item.id)}/>
        ))}
      </div>
      {extraFuncType && extraFuncType === "addNew" ? (
        <>
          <TubeSubmit
            onClick={() => setExtraFuncDialog({isOpen: true, name: ""})}
            label={extraFuncLabel}
            variant={"outlined"}
            className={"multiselect-extra-func-button"}
          />
          <DialogComponent
            onClose={() => setExtraFuncDialog({isOpen: false, name: ""})}
            open={extraFuncDialog.isOpen}
            title={extraFuncLabel}
            content={(
              <TextInput
                noRedux
                noReduxValue={extraFuncDialog.name}
                noReduxOnChange={(e) => setExtraFuncDialog({isOpen: true, name: e})}
                label={"Type here..."}
              />
            )}
            buttons={[
              {
                variant: "contained",
                label: "Add",
                onClick: handleExtraFunc,
              },
              {
                  variant: "outlined",
                  label: "Cancel",
                  color: "secondary",
                  onClick: () => setExtraFuncDialog({isOpen: false, name: ""}),
              }
            ]}  
          />
        </>
      ) : null}
    </div>
  );
}

MultiSelect.propTypes = {
    label: PropTypes.string.isRequired,
    stateName: PropTypes.string.isRequired,
    valueName: PropTypes.string.isRequired,
    dispatchFunc: PropTypes.func.isRequired,
    data: PropTypes.any.isRequired,
    disabled: PropTypes.bool
};