import "./TubeCover.css";
import { useSelector } from 'react-redux';

export default function TubeCover(props){
    const { logo, cover, stateName } = props;
    const value = useSelector((state) => state[stateName]);
    
    return (
        <div className="tube-cover" style={{backgroundImage: `url(${value[cover]})`}}>
            <div class="tube-cover-logo" style={{backgroundImage: `url(${value[logo]})`}}/>
        </div>
    );
}