import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { del } from '../../utils/fetch';
import DialogComponent from '../dialog/Dialog';
import { IconButton } from '@mui/material';
import { appUrl } from '../../utils/fetch';
import TubeSubmit from './TubeSubmit';
import { DeleteOutline } from '@mui/icons-material';

export default function Gallery(props) {
    const { label, stateName, valueName, dispatchFunc, disabled, deleteUrl, sx, thumbnail } = props;
    const photos = useSelector((state) => state[stateName][valueName]);
    const [ openPhoto, setOpenPhoto ] = React.useState(false);
    const [ openedPhoto, setOpenedPhoto ] = React.useState(0);
    const [ openDelete, setOpenDelete ] = React.useState(false);
    const [ idDelete, setIdDelete ] = React.useState(0);
    const dispatch = useDispatch();

    const handleChange = (files) => {
        let value = photos;
        Object.keys(files).forEach(i => {
            value = [ ...value, URL.createObjectURL(files[i]) ];
        });
        dispatch(dispatchFunc({[valueName]: value}));
        dispatch(dispatchFunc({[`${valueName}ToUpload`]: files}));
    }

    const handleOpenPhoto = (i) => {
        setOpenedPhoto(i);
        setOpenPhoto(true);
    }

    const handleDelete = (i) => {
        setOpenDelete(true);
        setIdDelete(i);
    }

    const submitDelete = () => {
        let photoUrl = photos[idDelete].split("/");
        if(`${photoUrl[0]}//${photoUrl[2]}/cpanel/public` === appUrl){
            let photoFile = photoUrl.pop();
            let photoHash = photoFile.split(".")[0];
            del(`${appUrl}/${deleteUrl}/${photoHash}`);
        }
        let newPhotos = [ ...photos ];
        newPhotos.splice(idDelete, 1);
        dispatch(dispatchFunc({[valueName]: newPhotos}));
        setOpenDelete(false);
    }

    return (
        <div style={sx}>
            <div className='upload-label'>Photos</div>
            <div className='tube-gallery-container'>
                {photos.map((photo, i) => (
                    <div className='upload-thumbnail tube-gallery-item' style={{width: thumbnail.w, height: thumbnail.h, backgroundImage: `url(${photo})`}}>
                        <IconButton aria-label="delete" variant="" onClick={() => handleDelete(i)} sx={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            backgroundColor: "rgba(255,255,255,0.7) !important",
                            border: "1px solid #abc",
                            width: 32,
                            height: 32,
                            color: "#888"
                        }}>
                            <DeleteOutline />
                        </IconButton>
                    </div>
                    // <ImageListItem key={i}>
                    //     <img
                    //         src={photo}
                    //         alt={openedPhoto + 1}
                    //         loading="lazy"
                    //         
                    //         style={{
                    //             cursor: "pointer"
                    //         }}
                    //     />
                    
                    // </ImageListItem>
                ))}
            </div>
            <TubeSubmit
                variant="outlined"
                component="label"
                disabled={disabled}
                className="upload-button"
                label={(
                    <>
                        {label}
                        <input
                            type="file"
                            onChange={(e) => handleChange(e.target.files)}
                            multiple
                            hidden
                        />
                    </>
                )}
            />
            <DialogComponent 
                open={openPhoto}
                onClose={() => {setOpenPhoto(false)}}
                title="Gallery"
                content={(
                    <img
                        src={photos[openedPhoto]}
                        alt={openedPhoto + 1}
                        style={{
                            maxWidth: "calc(100vw - 100px)",
                            maxHeight: "calc(100vh - 215px)"
                        }}
                    />
                )}
                buttons={[
                    {
                        variant: "contained",
                        label: "Previous",
                        color: "primary",
                        disabled: openedPhoto > 0 ? false : true,
                        onClick: () => handleOpenPhoto(openedPhoto - 1)
                    },
                    {
                        variant: "contained",
                        label: "Next",
                        color: "primary",
                        disabled: openedPhoto + 1 < photos.length ? false : true,
                        onClick: () => handleOpenPhoto(openedPhoto + 1)
                    },
                    {
                        variant: "text",
                        label: "Close",
                        color: "primary",
                        onClick: () => setOpenPhoto(false),
                    }
                ]}    
            />
            <DialogComponent
                open={openDelete}
                onClose={() => {setOpenDelete(false)}}
                content="Are you sure you want to delete this item?"
                buttons={[
                    {
                        variant: "contained",
                        label: "Delete",
                        color: "error",
                        onClick: submitDelete
                    },
                    {
                        variant: "text",
                        label: "Cancel",
                        color: "primary",
                        onClick: () => setOpenDelete(false),
                    }
                ]}
            />
        </div>
    );
}

Gallery.propTypes = {
    label: PropTypes.string.isRequired,
    stateName: PropTypes.string.isRequired,
    valueName: PropTypes.string.isRequired,
    dispatchFunc: PropTypes.func.isRequired,
    deleteUrl: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    sx: PropTypes.object,
    thumbnail: PropTypes.object
};