import { useEffect, useRef, useState } from 'react';
import './TubeRolldown.css';
import { Link } from 'react-router-dom';

export default function TubeRolldown({ items, hoverRef }) {
    const [isVisible, setIsVisible] = useState(false);
    const [position, setPosition] = useState({ top: 0, left: 0 });
    const timeoutRef = useRef(null);
    const menuRef = useRef(null);

    const handleMouseEnter = () => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
            timeoutRef.current = null;
        }
        if (hoverRef.current) {
            const rect = hoverRef.current.getBoundingClientRect();
            setPosition({
                top: rect.bottom + window.scrollY,
                left: rect.left + window.scrollX,
            });
        }
        setIsVisible(true);
    };

    const handleMouseLeave = () => {
        timeoutRef.current = setTimeout(() => {
            setIsVisible(false);
        }, 200);
    };

    useEffect(() => {
        const targetElement = hoverRef.current;
        const menuElement = menuRef.current;
        
        const handleMouseLeaveFromMenu = () => {
            timeoutRef.current = setTimeout(() => {
                setIsVisible(false);
            }, 200);
        };

        if (targetElement) {
            targetElement.addEventListener("mouseenter", handleMouseEnter);
            targetElement.addEventListener("mouseleave", handleMouseLeave);
        }

        if (menuElement) {
            menuElement.addEventListener("mouseenter", () => {
                if (timeoutRef.current) {
                    clearTimeout(timeoutRef.current);
                }
            });
            menuElement.addEventListener("mouseleave", handleMouseLeaveFromMenu);
        }

        return () => {
            if (targetElement) {
                targetElement.removeEventListener("mouseenter", handleMouseEnter);
                targetElement.removeEventListener("mouseleave", handleMouseLeave);
            }
            if (menuElement) {
                menuElement.removeEventListener("mouseenter", () => {
                    if (timeoutRef.current) {
                        clearTimeout(timeoutRef.current);
                    }
                });
                menuElement.removeEventListener("mouseleave", handleMouseLeaveFromMenu);
            }
        };
    }, [ hoverRef ]);

    return (
        <div
            className="tube-rolldown"
            ref={menuRef}
            style={{
                position: "absolute",
                top: `${position.top}px`,
                left: `${position.left}px`,
                display: isVisible ? "flex" : "none",
            }}
        >
            {items && items.map((item, i) => (
                <Link
                    key={i}
                    className="tube-rolldown-item"
                    to={item.href || '#'}
                >
                    {item.label}
                </Link>       
            ))}
        </div>
    );
}
