import { useEffect, useRef, useState } from 'react';
import "./Player.css";
import PlayerControls from './PlayerControls';
import Cookies from 'js-cookie';
import TubeSubmit from '../form/TubeSubmit';
import { useSelector } from 'react-redux';
import { VASTClient } from 'vast-client';


export default function Player(props){
    const { src, length, preview, videoData, ads, vastUrl, vertical } = props;
    const videoRef = useRef(null);
    const videoConatinerRef = useRef(null);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const [showThumb, setShowThumb] = useState(true);
    const [reset, setReset] = useState(false);
    const [videoURL, setVideoURL] = useState(``);
    const [adSrc, setAdSrc] = useState(null);
    const [videoClickThroughUrl, setVideoClickThroughUrl] = useState(null);

    const [adPlaying, setAdPlaying] = useState(false);
    const [skipEnabled, setSkipEnabled] = useState(false);
    const [adTime, setAdTime] = useState(0);
    const settings = useSelector((state) => state.settings);

    useEffect(() => {
        if (ads?.ad5 && ads?.ad5[0]?.source) {
            const fetchVastAd = async () => {
                try {
                    const vastClient = new VASTClient();
                    const vastResponse = await vastClient.get(ads?.ad5[0].source);
                    const mediaFile = vastResponse.ads[0]?.creatives[0]?.mediaFiles[0]?.fileURL;
                    const videoClickThrough = vastResponse.ads[0]?.creatives[0]?.videoClickThroughURLTemplate?.url;
    
                    if (mediaFile) {
                        setAdSrc(mediaFile);
                        setVideoClickThroughUrl(videoClickThrough);
                    }
                } catch (error) {
                    console.error('Error fetching VAST ad:', error);
                }
            };
            fetchVastAd();
        }
    }, [ads]);

    const handleAdClick = async () => {
        if(videoClickThroughUrl){
            window.open(videoClickThroughUrl, '_blank');
        }
    };    

    useEffect(() => {
        if(src){
            let videoQuality = "";
            if(videoData.resolution[localStorage.getItem("quality")]){
                videoQuality = localStorage.getItem("quality");
            }
            else {
                videoQuality = "";
            }
            setVideoURL(`${src && src.replace(".mp4", `${((settings && !settings.cookiesNotification) || (Cookies.get('cookiesNotification') === "true" && localStorage.getItem("quality"))) ? videoQuality : ""}.mp4`)}`);
            setIsPlaying(false);
            setShowThumb(true);
            setReset(prevState => !prevState);
        }
    }, [src]);

    useEffect(() => {
        if(src){
            const handleStorageChange = () => {
                let videoQuality = "";
                if(
                    videoData.resolution[localStorage.getItem("quality")] &&
                    Cookies.get('cookiesNotification') === "true" &&
                    localStorage.getItem("quality")
                ){
                    videoQuality = localStorage.getItem("quality");
                }
                else {
                    videoQuality = "";
                }
                const newURL = `${src && src.replace(".mp4", `${videoQuality}.mp4`)}`;
                console.log([
                    newURL
                ]);
                if (newURL) {
                    setVideoURL(newURL);
                }
                setReset(prev => !prev);
            };
            window.addEventListener('storage', handleStorageChange);
            return () => {
                window.removeEventListener('storage', handleStorageChange);
            };
        }
    }, [src]);

    useEffect(() => {
        if(showThumb && isPlaying){
            setShowThumb(false);
        }
    }, [isPlaying]);

    const handlePlay = (bool) => {
        if(ads?.ad5?.length > 0 && bool && adTime < 5){
            setAdPlaying(true); 
            setIsPlaying(false);   
        }
        else {
            setIsPlaying(bool)
        }
    };

    // Skip timer logic
    useEffect(() => {
        if (adPlaying) {
            const interval = setInterval(() => {
                setAdTime(prev => {
                    if (prev >= 5) {
                        setSkipEnabled(true); // Enable skip after 5 seconds
                        clearInterval(interval);
                    }
                    return prev + 1;
                });
            }, 1000);

            return () => clearInterval(interval);
        }
    }, [adPlaying]);

    const handleSkipAd = () => {
        setAdPlaying(false);
        setIsPlaying(true);
    };

    const handleAdEnded = () => {
        setAdPlaying(false);
        setIsPlaying(true);
        videoRef.current.src = videoURL; // Switch to main video
        videoRef.current.play();
    };

    const toggleFullscreen = () => {
        if (!document.fullscreenElement) {
            videoConatinerRef.current.requestFullscreen().catch(err => {
                console.log(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
            });
        } else {
            document.exitFullscreen();
        }
        setIsFullScreen(prevState => !prevState);
    };
    
    return (
        <div className="tube-player" ref={videoConatinerRef} style={{backgroundImage: `url("${preview}")`}}>
            {(adPlaying && ads?.ad5?.length > 0) && (
                <div className="tube-player-ad-container">
                    <video
                        ref={videoRef}
                        src={adSrc}
                        onEnded={handleAdEnded}
                        autoPlay
                        controls={false}
                        className="tube-player-ad-video"
                        onClick={handleAdClick}
                    />
                    {skipEnabled ? (
                        <TubeSubmit
                            label={"Skip Ad"}
                            className="skip-ad-btn"
                            onClick={handleSkipAd}
                        />
                    ) : (
                        <TubeSubmit
                            label={`Skip in ${5 - adTime} seconds...`}
                            className="skip-ad-btn"
                            variant={"outlined"}
                        />
                    )}
                </div>
            )}
            {!adPlaying && ((ads?.ad1?.length > 0 || ads?.ad2?.length > 0) && !isPlaying) && (
                <>
                    {ads?.ad1?.length > 0 && (
                        <div className="tube-player-ad-1" dangerouslySetInnerHTML={{__html: ads?.ad1[0].source}} />
                    )}
                    {ads?.ad2?.length > 0 && (
                        <div className="tube-player-ad-2" dangerouslySetInnerHTML={{__html: ads?.ad2[0].source}} />
                    )}
                </>
            )}
            {!adPlaying && (
                <video
                    ref={videoRef}
                    src={videoURL}
                    onPlay={() => setIsPlaying(true)}
                    style={{ display: showThumb === false ? "block" : "none" }}
                />
            )}
            {!adPlaying && (
                <PlayerControls
                    videoRef={videoRef}
                    toggleFullscreen={toggleFullscreen}
                    isFullScreen={isFullScreen}
                    length={length}
                    videoData={videoData}
                    isPlaying={isPlaying}
                    setIsPlaying={(bool) => handlePlay(bool)}
                    videoURL={videoURL}
                    reset={reset}
                    vertical={vertical}
                />
            )}
        </div>
    );
}