import { CameraAltOutlined, ChatOutlined, FavoriteBorderOutlined, PersonAddAlt, StarBorderOutlined, VideocamOutlined, VisibilityOutlined } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { appUrl, calculateAge, get } from '../../utils/fetch';
import TubeButton from '../form/TubeChip';
import TubeSubmit from '../form/TubeSubmit';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import "./User.css";
import Tabs from '../navigation/Tabs';

function User(props) {
    const { id, subTab } = useParams();
    
    const [ user, setUser ] = useState({
        models: [],
        logo: ''
    });

    useEffect(() => {
        window.scrollTo({
            top: 0
        });
    }, []);

    const [ videos, setVideos ] = useState([]);
    const [ albums, setAlbums ] = useState([]);
    const [ models, setModels ] = useState([]);

    const [ featured, setFeatured ] = useState([]);

    useEffect(() => {
        get(`${appUrl}/api/user/${id}`, (result) => {setUser(result.data)});
        get(`${appUrl}/api/videos?limit=2&status=1&order=desc&column=id&user_id=${id}`, (result) => {setFeatured(result.data.data)});
        get(`${appUrl}/api/videos?limit=8&status=1&order=desc&column=id&user_id=${id}`, (result) => {setVideos(result.data.data)});
        get(`${appUrl}/api/albums?limit=6&order=desc&column=id&user_id=${id}`, (result) => {setAlbums(result.data.data)});
        get(`${appUrl}/api/models?limit=6&order=desc&column=id&user_id=${id}`, (result) => {setModels(result.data.data)});
    }, []);

    return (
        <div className='user-content'>
            <div className='user-content-header'>
                <div className='user-content-header-background' style={{backgroundImage: `url(${appUrl}/avatars/${user.cover})`}} />
                <div className='user-content-header-info'>
                    <div>
                        <div className='user-content-header-logo'  style={{backgroundImage: `url(${appUrl}/avatars/${user.avatar})`}} />
                        <div className='user-content-header-rc'>
                            <h1>{user.display_name}</h1>
                            <div className='user-content-header-shortdesc'>
                                {user.short_description}
                            </div>
                            <div className='user-content-header-stats'>
                                <TubeButton
                                    label={(
                                        <>
                                            <VideocamOutlined /> {user.videos}
                                            <VisibilityOutlined /> 0
                                        </>
                                    )}
                                />
                                <TubeButton
                                    label={(
                                        <>
                                            <CameraAltOutlined /> {user.albums}
                                            <VisibilityOutlined /> 0
                                        </>
                                    )}
                                />
                                <TubeButton
                                    label={(
                                        <>
                                            <PersonAddAlt /> 0
                                        </>
                                    )}
                                />
                                <TubeButton
                                    label={(
                                        <>
                                            <ChatOutlined /> 0
                                        </>
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='user-content-subscribe'>
                <div className='user-content-subscribe-lc'>
                    <TubeSubmit
                        onClick={() => {}}
                        label="0"
                        icon="like"
                        variant="plainOutlined"                
                    />
                    <TubeSubmit
                        onClick={() => {}}
                        label="0"
                        icon="dislike"
                        variant="plainOutlined"                
                    />
                    <TubeSubmit
                        onClick={() => {}}
                        label="0"
                        icon="favorite"
                        variant="plainOutlined"                
                    />
                    <TubeSubmit
                        onClick={() => {}}
                        label="Share"
                        icon="share"
                        variant="plainOutlined"                
                    />
                    <TubeSubmit
                        onClick={() => {}}
                        label="Report"
                        icon="flag"
                        variant="plainOutlined"                
                    />
                    <TubeSubmit
                        onClick={() => {}}
                        label="Block user"
                        icon="close"
                        variant="plainOutlined"                
                    />
                </div>
                <div className='user-content-subscribe-rc'>
                    <TubeSubmit
                        onClick={() => {}}
                        label="Subscribe"          
                    />
                    <TubeSubmit
                        onClick={() => {}}
                        label="Join"
                        variant={"outlined"}          
                    />
                </div>
            </div>
            <div className='line' />
            <Tabs
                title=""
                tabs={[
                    { 
                        title: "About",
                        href: `/profile/${id}`,
                        icon: <FavoriteBorderIcon/>,
                        active: !subTab,
                        content: (
                            <div className='user-content-about'>
                                <div className='user-content-about-titles'>
                                    <h2>About</h2>
                                </div>
                                <div className='about-me'>
                                    {user.about_me}
                                </div>
                                <div className='user-content-about-stats'>
                                    <div>Member since</div>
                                    <div><span>{user.created_at}</span></div>
                                    <div>Country</div>
                                    <div><span>{(user.country && user.country[0]) && user.country[0].name}</span></div>
                                    <div>City</div>
                                    <div><span>{user.city}</span></div>
                                    <div>Date of birth</div>
                                    <div><span>{user.birth_date && new Date(user.birth_date).toLocaleDateString('en-US', { month: 'numeric', day: 'numeric', year: 'numeric' })}</span></div>
                                    <div>Orientation</div>
                                    <div><span>{user.orientationText}</span></div>
                                    <div>Relationship</div>
                                    <div><span>{user.relationshipText}</span></div>
                                    <div>Age</div>
                                    <div><span>{calculateAge(user.birth_date)}</span></div>
                                    <div>Languages</div>
                                    <div><span>{user.languages && user.languages.map(language => language.name).join(", ")}</span></div>
                                </div>
                                <div className='user-content-about-stats'>
                                    <div>Education</div>
                                    <div><span>{user.educationText}</span></div>
                                    <div>Ethnicity</div>
                                    <div><span>{user.ethnicityText}</span></div>
                                    <div>Drinking</div>
                                    <div><span>{user.drinkingText}</span></div>
                                    <div>Smoking</div>
                                    <div><span>{user.smokingText}</span></div>
                                    <div>Hair length</div>
                                    <div><span>{user.hairLengthText}</span></div>
                                    <div>Hair color</div>
                                    <div><span>{user.hairColorText}</span></div>
                                    <div>Eye color</div>
                                    <div><span>{user.eyeColorText}</span></div>
                                </div>
                            </div>
                        )
                    },
                    { 
                        title: "Videos",
                        href: `/profile/${id}/videos`,
                        icon: <VideocamOutlined/>,
                        active: subTab === "videos",
                        content: (
                            <>
                                <div className='user-content-about-titles'>
                                    <h2>Videos</h2>
                                </div>
                                <div className="categories-content-videos">
                                    {videos.map(video => (
                                        <NavLink to={`/video/${video.id}`}>
                                            <div className="categories-content-videos-video" style={{backgroundImage: `url(${appUrl}/${video.thumb})`}}>
                                                {video.thumb === "no-preview" ? (
                                                    <div className="no-preview-text">No preview</div>
                                                ) : (
                                                    <video src={`${appUrl}/${video.thumb.replace("jpg", "mp4").replace("thumbs/", "thumbs/preview-")}`} autoPlay loop muted/>
                                                )}
                                                <div className="categories-content-videos-video-length">
                                                    {video.duration}
                                                </div>
                                                <div className='categories-content-channels-info'>
                                                    <span><VisibilityOutlined /> 0</span>
                                                    <span><FavoriteBorderOutlined /> 0</span>
                                                    <span><ChatOutlined /> 0</span>
                                                </div> 
                                            </div>
                                            <div className="categories-content-videos-user-wrapper">
                                                <div className="categories-content-videos-user" style={{backgroundImage: `url(${appUrl}/${video.userAvatar})`}}/>
                                                <div className="categories-content-videos-username">{video.user}</div>
                                            </div>
                                            <div className="categories-content-videos-name">{video.title}</div>
                                        </NavLink>
                                    ))}
                                </div>
                            </>
                        )
                    },
                    { 
                        title: "Galleries",
                        href: `/profile/${id}/galleries`,
                        icon: <CameraAltOutlined/>,
                        active: subTab === "galleries",
                        content: (
                            <>
                                <div className='user-content-about-titles'>
                                    <h2>Galleries</h2>
                                </div>
                                <div className="categories-content-albums">
                                    {albums.map(album => (
                                        <NavLink to={`/gallery/${album.id}`}>
                                            <div className="categories-content-albums-album"> 
                                                <div className="categories-content-albums-album-thumb" style={{backgroundImage: `url(${appUrl}/${album.thumb})`}}/>
                                                <div className="categories-content-albums-album-media"><CameraAltOutlined /> {album.photos}</div>    
                                            </div>
                                            <div className="categories-content-videos-user-wrapper">
                                                <div className="categories-content-videos-user" style={{backgroundImage: `url(${appUrl}/${album.userAvatar})`}}/>
                                                <div className="categories-content-videos-username">{album.user}</div>
                                            </div>
                                            <div className="categories-content-albums-name">{album.name}</div>
                                            <div className='categories-content-albums-info'>
                                                <VisibilityOutlined /> 0
                                                <FavoriteBorderOutlined /> 0
                                                <ChatOutlined /> 0
                                            </div>
                                        </NavLink>
                                    ))}
                                </div>
                            </>
                        )
                    },
                    { 
                        title: "Friends",
                        href: `/profile/${id}/friends`,
                        icon: <PersonAddAlt/>,
                        active: subTab === "friends",
                        content: (
                            <>
                                <div className='user-content-about-titles'>
                                    <h2>Friends</h2>
                                </div>
                            </>
                        )
                    }
                ]}
            />
        </div>
    );
}

export default User;