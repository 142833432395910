
import "./Albums.css";
import { NavLink, useParams } from "react-router-dom";
import Sidebar from "./Sidebar";
import { useEffect, useState } from "react";
import { appUrl, get } from "../../utils/fetch";
import { CameraAltOutlined, VideocamOutlined, ChatOutlined, FavoriteBorderOutlined, VisibilityOutlined } from "@mui/icons-material";
import DataTablePagination from "../dataTable/DataTablePagination";
import AlbumList from "../dataTable/AlbumList";
import { edit as editHeader } from '../../slices/headerSlice';
import { useDispatch, useSelector } from "react-redux";

export default function Albums(props){
    const { page, search } = useParams();
    const [ albums, setAlbums ] = useState([]);
    const [ totalPages, setTotalPages ] = useState(1);

    const settings = useSelector(state => state.settings);
    const dispatch = useDispatch();

    useEffect(() => {
        get(`${appUrl}/api/albums?limit=18&order=desc&column=date_scheduled&page=${page}&name=${search ? search : ""}`, (result) => {
            setAlbums(result.data.data);
            setTotalPages(result.data.last_page);
        });
        dispatch(editHeader({title: `Galleries - ${settings.siteTitle}`}));
    }, [page]);
    
    return (
        <div className="categories-wrapper">
            <Sidebar videos />
            <div className="categories-content">
                <div className="categories-content-header">
                    <h1>{search ? `Search result for: ${search}` : "Galleries"}</h1>
                </div>
                <div className="categories-content-albums">
                    <AlbumList data={albums} />
                </div>
                <div className="categories-content-videos-pagination">
                    <DataTablePagination
                        page={page ? page : 1}
                        url={`/galleries${search ? `/search/${search}` : ""}`}
                        totalPages={totalPages}
                    />
                </div>
            </div>
        </div>
    );
}