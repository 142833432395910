
import "./Categories.css";
import TubeSubmit from "../form/TubeSubmit";
import { useNavigate, useParams } from "react-router-dom";
import TubeButton from "../form/TubeChip";
import Sidebar from "./Sidebar";
import { useEffect, useState } from "react";
import { appUrl, get } from "../../utils/fetch";
import DataTablePagination from "../dataTable/DataTablePagination";
import VideoList from "../dataTable/VideoList";
import { edit as editHeader } from '../../slices/headerSlice';
import { useDispatch, useSelector } from "react-redux";

export default function Videos(props){
    const navigate = useNavigate();
    const { page, category, tag, search } = useParams();
    const [ videos, setVideos ] = useState([]);
    const [ totalPages, setTotalPages ] = useState(1);
    const [ categoryData, setCategoryData ] = useState({});
    const [ tagData, setTagData ] = useState({});
    const [ ad, setAd ] = useState([]);

    const settings = useSelector(state => state.settings);
    const dispatch = useDispatch();

    const getData = (getDataCategory = {}, column = 'id') => {
        get(`${appUrl}/api/videos?limit=24&order=desc&column=${column}&status=1&page=${page ? page : 1}&category_id=${getDataCategory?.id ? getDataCategory.id : 0}&tag_id=${tagData?.id && !getDataCategory.id ? tagData?.id : 0}&name=${search ? search : ""}`, (result) => {
            setVideos(result.data.data)
            setTotalPages(result.data.last_page);
            setAd(result.data.ad6)
        });
    }

    useEffect(() => {
        if(!tag){
            if(category && category !== "all"){
                if( category === "newest-videos" ){
                    getData({}, 'date_scheduled');
                    dispatch(editHeader({
                        title: `Newest videos - ${settings.siteTitle}`
                    }));
                    setCategoryData({
                        name: "Newest videos",
                        title_slug: 'newest-videos'
                    });
                    return;
                }
                if( category === "most-watched" ){
                    getData({}, 'views');
                    dispatch(editHeader({
                        title: `Most watched - ${settings.siteTitle}`
                    }));
                    setCategoryData({
                        name: "Most watched",
                        title_slug: 'most-watched'
                    });
                    return;
                }
                if( category === "top-rated" ){
                    getData({}, 'likes');
                    dispatch(editHeader({
                        title: `Top rated - ${settings.siteTitle}`
                    }));
                    setCategoryData({
                        name: "Top rated",
                        title_slug: 'top-rated'
                    });
                    return;
                }
                get(`${appUrl}/api/content-category/${category}`, (result) => {
                    if (result.response?.status === 404) {
                        navigate('/404');
                        return;
                    }
                    setCategoryData(result.data);
                    getData(result.data);
                    dispatch(editHeader({
                        title: `${result.data.seo_title || result.data.name} - ${settings.siteTitle}`,
                        description: result.data.seo_description,
                        keywords: result.data.seo_keywords
                    }));
                });
            }
            else {
                getData();
                setCategoryData({});
                dispatch(editHeader({title: `Videos - ${settings.siteTitle}`}));
            }
        }   
    }, [page, category, search]);

    useEffect(() => {
        window.scrollTo({
            top: 0
        });
    }, []);

    useEffect(() => {
        if(tag){
            get(`${appUrl}/api/content-tag/${tag}`, (result) => {
                if (result.response?.status === 404) {
                    navigate('/404');
                    return;
                }
                setTagData(result.data);
                dispatch(editHeader({
                    title: `${result.data.seo_title || result.data.name} - ${settings.siteTitle}`,
                    description: result.data.seo_description,
                    keywords: result.data.seo_keywords
                }));
                setCategoryData({});
            });
        }
        else {
            setTagData({});
        }
    }, [tag]);

    useEffect(() => {
        if(tagData?.id){
            getData();
        }
    }, [tagData, page])
    
    return (
        <div className="categories-wrapper">
            <Sidebar videos/>
            <div className="categories-content">
                <div className="categories-content-header">
                    <h1>{
                        categoryData.name ? categoryData.name : 
                            tagData.name ? tagData.name : 
                                search ? `Search result for: ${search}` :
                                    "Videos"
                        }
                    </h1>
                    {categoryData.parent && (
                        <TubeSubmit
                            redirectTo={`/videos/${categoryData.parent.title_slug}`}
                            label={categoryData.parent.name}
                            icon="back"
                            variant="plainOutlined"                
                        />
                    )}
                </div>
                <div className="categories-content-description">
                    {categoryData.description}
                </div>
                {!tag && (
                    <>
                        <h2>Tags</h2>
                        <div className="categories-content-tags">
                            {categoryData.tags && categoryData.tags.map(tag => (
                                <TubeButton
                                    label={tag.name}
                                    onClick={() => navigate(`/videos/tag/${tag.title_slug}`)}
                                />
                            ))}
                        </div>
                    </>
                )}
                <div className="line"/>
                <div className="categories-content-videos">
                    <VideoList data={videos} />
                </div>
                {(ad && ad.length) > 0 && (
                    <div className="tube-player-ad-6" dangerouslySetInnerHTML={{__html: ad[0].source}} />
                )}
                <div className="categories-content-videos-pagination">
                    <DataTablePagination
                        page={page ? page : 1}
                        url={`/videos/${
                            categoryData?.title_slug ? categoryData.title_slug : 
                                tag ? `tag/${tag}` : 
                                    search ? `search/${search}` :
                                        "all"
                        }`}
                        totalPages={totalPages}
                    />
                </div>
            </div>
        </div>
    );
}