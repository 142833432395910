import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    title: "Loading...",
    description: "Loading...",
    keywords: "Loading...",
    menuOpen: false
}

export const headerSlice = createSlice({
    name: 'header',
    initialState: initialState,
    reducers: {
        edit: (state, payload) => {
            state = {
                ...state, 
                ...payload.payload
            };
            return state;
        }
    },
})

export const { edit } = headerSlice.actions

export default headerSlice.reducer