import { CameraAltOutlined, ChatOutlined, FavoriteBorderOutlined, PersonAddAlt, StarBorderOutlined, VideocamOutlined, VisibilityOutlined } from '@mui/icons-material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { appUrl, capitalizeEveryWord, formatNumber, get } from '../../utils/fetch';
import TubeButton from '../form/TubeChip';
import TubeSubmit from '../form/TubeSubmit';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import "./Channel.css";
import Tabs from '../navigation/Tabs';
import { moduleUser } from '../../utils/modules';
import VideoList from '../dataTable/VideoList';
import AlbumList from '../dataTable/AlbumList';
import ModelList from '../dataTable/ModelList';
import { edit as editHeader } from '../../slices/headerSlice';
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from '@mui/material';
import DataTablePagination from '../dataTable/DataTablePagination';

function Channel(props) {
    const { id, subTab, page } = useParams();

    const [ totalPagesModels, setTotalPagesModels ] = useState(1);
    
    const [ channel, setChannel ] = useState({
        models: [],
        logo: ''
    });

    useEffect(() => {
        window.scrollTo({
            top: 0
        });
    }, [id]);

    const navigate = useNavigate();

    const settings = useSelector(state => state.settings);
    const dispatch = useDispatch();

    const [ videos, setVideos ] = useState([]);
    const [ albums, setAlbums ] = useState([]);
    const [ models, setModels ] = useState([]);

    useEffect(() => {
        get(`${appUrl}/api/channel/${id}`, (result) => {
            if (result.response?.status === 404) {
                navigate('/404');
                return;
            }
            setChannel(result.data);
            dispatch(editHeader({title: `${result.data.title} - ${settings.siteTitle}`}));
            get(`${appUrl}/api/videos?limit=8&order=desc&status=1&column=id&channel_id=${result.data.id}`, (result) => {setVideos(result.data.data)});
            get(`${appUrl}/api/albums?limit=6&order=desc&column=id&channel_id=${result.data.id}`, (result) => {setAlbums(result.data.data)});
            get(
                `${appUrl}/api/models?limit=6&order=desc&column=id&channel_id=${result.data.id}&page=${page}`,
                (result) => {
                    setModels(result.data.data)
                    setTotalPagesModels(result.data?.last_page);
                }
            );
        });
    }, [page]);

    return (
        <div className='channel-content'>
            <div className='channel-content-header'>
                <div className='channel-content-header-background' style={{backgroundImage: `url(${appUrl}/channel-banners/${channel?.cover})`}} />
                <div className='channel-content-header-info'>
                    <div>
                        <div className='channel-content-header-logo'  style={{backgroundImage: `url(${appUrl}/channel-banners/${channel?.logo})`}} />
                        <div className='channel-content-header-rc'>
                            <div className='channel-content-header-title-wrapper'>
                                <h1>{channel?.title}</h1>
                                <div className='channel-content-header-joinbtn'>
                            </div>
                                {channel?.referral_link && (
                                    <TubeSubmit
                                        onClick={() => {window.open(channel?.referral_link)}}
                                        label={channel?.referral_label || "Join now"}
                                        variant={"outlined"}          
                                    />
                                )}
                            </div>
                            <div className='channel-content-header-shortdesc'>
                                {channel?.short_description}
                            </div>
                            <div className='channel-content-header-stats'>
                                <TubeButton
                                    label={(
                                        <>
                                            <VideocamOutlined /> {formatNumber(channel?.videos)}
                                            <VisibilityOutlined /> {formatNumber(channel?.videoViews)}
                                        </>
                                    )}
                                />
                                <TubeButton
                                    label={(
                                        <>
                                            <CameraAltOutlined /> {formatNumber(channel?.albums)}
                                            <VisibilityOutlined /> {formatNumber(channel?.albumViews)}
                                        </>
                                    )}
                                />
                                {moduleUser && (
                                    <TubeButton
                                        label={(
                                            <>
                                                <PersonAddAlt /> 0
                                            </>
                                        )}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    <div className='channel-content-header-models'>
                        <span>Models</span>
                        {channel?.models.map(model => (
                            <Tooltip title={model.name} arrow>
                                <div className='channel-content-header-model' style={{backgroundImage: `url("${model.thumbnail}")`}} />
                            </Tooltip>
                        ))}
                    </div>
                    <div className='channel-content-header-media'>
                        {channel?.featured && channel?.featured.map((video, i) => (
                            <Tooltip title={video.title} key={i} arrow disableInteractive>
                                <NavLink to={`/video/${video.id}`}>
                                    <div className="categories-content-videos-video" style={{backgroundImage: `url(${appUrl}/${video.thumb})`}}>
                                        {!video.thumb || video.thumb === "no-preview" ? (
                                            <div className="no-preview-text">No preview</div>
                                        ) : (
                                            <video src={`${appUrl}/${video.thumb.replace("jpg", "mp4").replace("thumbs/", "thumbs/preview-")}`} autoPlay loop muted/>
                                        )}
                                    </div>
                                </NavLink>
                            </Tooltip>
                        ))}
                    </div>
                </div>
            </div>
            {/* <div className='channel-content-subscribe'>
                <div className='channel-content-subscribe-lc'>
                    {moduleUser && (
                        <>
                            <TubeSubmit
                                onClick={() => {}}
                                label="Subscribe"          
                            />
                        </>
                    )}
                </div>
                <div className='channel-content-subscribe-rc'>
                    {moduleUser && (
                        <>
                            <TubeSubmit
                                onClick={() => {}}
                                label="0"
                                icon="favorite"
                                variant="plainOutlined"                
                            />
                        </>
                    )}
                    <TubeSubmit
                        onClick={() => {}}
                        label="Report"
                        icon="flag"
                        variant="plainOutlined"                
                    />
                </div>
            </div> */}
            <Tabs
                title=""
                tabs={[
                    { 
                        title: `About`,
                        href: `/channel/${id}`,
                        icon: <FavoriteBorderIcon/>,
                        active: !subTab,
                        content: (
                            <div className='channel-content-about'>
                                <div className='channel-content-about-titles'>
                                    <h2>Featured</h2>
                                    <h2>About {channel?.title}</h2>
                                </div>
                                <div className='channel-content-featured'>
                                    <VideoList data={channel?.featured} limit={2} />
                                </div>
                                <div className='channel-content-description'>
                                    {channel?.description}
                                    <div className="categories-content-tags">
                                        {channel?.categoriesNew && channel?.categoriesNew.map(category => (
                                            <TubeButton
                                                label={capitalizeEveryWord(category.name)}
                                                onClick={() => navigate(`/videos/${category.title_slug}`)}
                                            />
                                        ))}
                                    </div>
                                </div>
                            </div>
                        )
                    },
                    { 
                        title: "Videos",
                        href: `/channel/${id}/videos`,
                        icon: <VideocamOutlined/>,
                        active: subTab === "videos",
                        content: (
                            <>
                                <div className='channel-content-about-titles'>
                                    <h2>Videos from {channel?.title}</h2>
                                </div>
                                <div className="categories-content-videos">
                                    <VideoList data={videos} />
                                </div>
                            </>
                        )
                    },
                    { 
                        title: "Galleries",
                        href: `/channel/${id}/galleries`,
                        icon: <CameraAltOutlined/>,
                        active: subTab === "galleries",
                        content: (
                            <>
                                <div className='channel-content-about-titles'>
                                    <h2>Galleries from {channel?.title}</h2>
                                </div>
                                <div className="categories-content-albums">
                                    <AlbumList data={albums} />
                                </div>
                            </>
                        )
                    },
                    { 
                        title: "Models",
                        href: `/channel/${id}/models`,
                        icon: <StarBorderOutlined/>,
                        active: subTab === "models",
                        content: (
                            <>
                                <div className='channel-content-about-titles'>
                                    <h2>Models from {channel?.title}</h2>
                                </div>
                                <div className="categories-content-models">
                                    <ModelList data={models} />
                                </div>
                                <div className="categories-content-videos-pagination">
                                    <DataTablePagination
                                        page={page ? page : 1}
                                        url={`/channel/${id}/models`}
                                        totalPages={totalPagesModels}
                                    />
                                </div>
                            </>
                        )
                    }
                ]}
            />
        </div>
    );
}

export default Channel;

Channel.propTypes = {
    id: PropTypes.number
};