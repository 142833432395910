import { CameraAltOutlined, ChatOutlined, FavoriteBorderOutlined, PersonAddAlt, VideocamOutlined, VisibilityOutlined } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { appUrl, get } from "../../utils/fetch";
import Sidebar from "./Sidebar";
import "./Home.css";
import TubeButton from '../form/TubeChip';
import TubeSubmit from "../form/TubeSubmit";
import { moduleUser } from "../../utils/modules";
import VideoList from "../dataTable/VideoList";
import AlbumList from "../dataTable/AlbumList";
import ModelList from "../dataTable/ModelList";
import { edit as editHeader } from '../../slices/headerSlice';
import { useDispatch, useSelector } from "react-redux";

export default function Home(props){
    const user = null;
    const userVideos = null;
    const [ home, setHome ] = useState({
        videosBeingWatchedNow: [],
        videosNew: [],
        albums: [],
        models: []
    });
    const settings = useSelector(state => state.settings);
    const dispatch = useDispatch();

    useEffect(() => {
        get(`${appUrl}/api/home`, (result) => {
            setHome(result.data);
        });
        dispatch(editHeader({
            title: settings.siteTitle,
            description: settings.siteDescription,
            keywords: settings.siteKeywords
        }));
    }, []);

    return (
        <div className="home-wrapper">
            <Sidebar videos/>
            <div className="home-content">
                <h1>Being watched now</h1>
                <div className="categories-content-videos">
                    <VideoList data={home.videosBeingWatchedNow} />
                </div>
                <h1>Latest videos</h1>
                <div className="categories-content-videos">
                    <VideoList data={home.videosNew} />
                </div>
                <h1>Trending galleries</h1>
                <div className="categories-content-albums">
                    <AlbumList data={home.albums} />
                </div>
                {user && (
                    <>
                        <div className='user-content-header'>
                            <div className='user-content-header-background' style={{backgroundImage: `url(${appUrl}/avatars/${user.cover})`}} />
                            <div className='user-content-header-info'>
                                <div>
                                    <div className='user-content-header-logo'  style={{backgroundImage: `url(${appUrl}/avatars/${user.avatar})`}} />
                                    <div className='user-content-header-rc'>
                                        <h1>{user.display_name}</h1>
                                        <div className='user-content-header-shortdesc'>
                                            {user.short_description}
                                        </div>
                                        <div className='user-content-header-stats'>
                                            <TubeButton
                                                label={(
                                                    <>
                                                        <VideocamOutlined /> {user.videos}
                                                        <VisibilityOutlined /> 0
                                                    </>
                                                )}
                                            />
                                            <TubeButton
                                                label={(
                                                    <>
                                                        <CameraAltOutlined /> {user.albums}
                                                        <VisibilityOutlined /> 0
                                                    </>
                                                )}
                                            />
                                            <TubeButton
                                                label={(
                                                    <>
                                                        <PersonAddAlt /> 0
                                                    </>
                                                )}
                                            />
                                            <TubeButton
                                                label={(
                                                    <>
                                                        <ChatOutlined /> 0
                                                    </>
                                                )}
                                            />
                                        </div>
                                    </div>
                                    <div className='user-content-header-buttons'>
                                        <TubeSubmit
                                            onClick={() => {}}
                                            label="Subscribe"          
                                        />
                                        <TubeSubmit
                                            onClick={() => {}}
                                            label="Join"
                                            variant={"outlined"}          
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="categories-content-videos">
                            {userVideos.map(video => (
                                <NavLink to={`/video/${video.id}`}>
                                    <div className="categories-content-videos-video" style={{backgroundImage: `url(${appUrl}/${video.thumb})`}}>
                                        {video.thumb === "no-preview" ? (
                                            <div className="no-preview-text">No preview</div>
                                        ) : (
                                            <video src={`${appUrl}/${video.thumb.replace("jpg", "mp4").replace("thumbs/", "thumbs/preview-")}`} autoPlay loop muted/>
                                        )}
                                        <div className="categories-content-videos-video-length">
                                            {video.duration}
                                        </div>
                                        <div className='categories-content-channels-info'>
                                            <span><VisibilityOutlined /> 0</span>
                                            <span><FavoriteBorderOutlined /> 0</span>
                                            <span><ChatOutlined /> 0</span>
                                        </div> 
                                    </div>
                                    <div className="categories-content-videos-user-wrapper">
                                        <div className="categories-content-videos-user" style={{backgroundImage: `url(${appUrl}/${video.userAvatar})`}}/>
                                        <div className="categories-content-videos-username">{video.user}</div>
                                    </div>
                                    <div className="categories-content-videos-name">{video.title}</div>
                                </NavLink>
                            ))}
                        </div>
                    </>
                )}
                <h1>Featured models</h1>
                <div className="categories-content-models">
                    <ModelList data={home.models} />
                </div>
            </div>
        </div>
    );
}