import { useEffect, useState } from "react";
import { moduleUser } from "../../utils/modules";
import { ChatOutlined, FavoriteBorderOutlined, VisibilityOutlined } from "@mui/icons-material";
import TubeSubmit from "../form/TubeSubmit";
import { appUrl, capitalizeEveryWord, formatNumber, post } from "../../utils/fetch";
import { useNavigate } from "react-router-dom";
import TubeButton from "../form/TubeChip";

export default function VideoDetail(props){
    const { video, shareFunc } = props; 
    
    const navigate = useNavigate();
    
    const [ showMore, setShowMore ] = useState(false);
    const [ likes, setLikes ] = useState(0);
    const [ dislikes, setDislikes ] = useState(0);
    const [ clientLike, setClientLike ] = useState({
        isLiked: false,
        type: 0
    });

    useEffect(() => {
        setLikes(video?.likes);
        setDislikes(video?.dislikes);
        if(video?.clientLike){
            setClientLike(video?.clientLike);
        }
    }, [video]);

    const handleLike = (type) => {
        switch(type){
            case 0:
                if(clientLike.isLiked && clientLike.type === 0){
                    setLikes((prevState) => (prevState - 1)); 
                    setClientLike({
                        isLiked: false,
                        type: 0
                    });   
                }
                else {
                    if(clientLike.isLiked && clientLike.type === 1){
                        setDislikes((prevState) => (prevState - 1));    
                    } 
                    setClientLike({
                        isLiked: true,
                        type: 0
                    });   
                    setLikes((prevState) => (prevState + 1));
                }
                break;
            case 1:
                if(clientLike.isLiked && clientLike.type === 1){
                    setDislikes((prevState) => (prevState - 1));   
                    setClientLike({
                        isLiked: false,
                        type: 0
                    });     
                }
                else {
                    if(clientLike.isLiked && clientLike.type === 0){
                        setLikes((prevState) => (prevState - 1));    
                    }
                    setClientLike({
                        isLiked: true,
                        type: 1
                    });   
                    setDislikes((prevState) => (prevState + 1));
                }
                break;
            default:
                return;
        }
        post(`${appUrl}/api/video-like`, {
            id: video?.id,
            type: type    
        });
    }
    
    return (
    <>
        <h1>{video?.name}</h1>
        {showMore && (
            <div className='video-content-description'>
                {video?.description}
            </div>
        )}
        <div className="line"/>
        <div className='video-content-interactions'>
            <div className='video-content-interactions-info'>
                <VisibilityOutlined /> {formatNumber(video?.views)}
                {moduleUser && (
                    <>
                        <FavoriteBorderOutlined /> 0
                        <ChatOutlined /> 0
                    </>
                )}
            </div>
            <div className="video-content-interactions-buttons">
                {moduleUser && (
                    <>
                        <TubeSubmit
                            onClick={() => {}}
                            label="0"
                            icon="favorite"
                            variant="plainOutlined"                
                        />
                    </>
                )}
                
                <TubeSubmit
                    onClick={() => handleLike(0)}
                    label={formatNumber(likes)}
                    icon="like"
                    variant={(clientLike.isLiked && clientLike.type === 0) ? "contained" : "plainOutlined"}                
                />
                <TubeSubmit
                    onClick={() => handleLike(1)}
                    label={formatNumber(dislikes)}
                    icon="dislike"
                    variant={(clientLike.isLiked && clientLike.type === 1) ? "contained" : "plainOutlined"}             
                />
                <TubeSubmit
                    onClick={shareFunc}
                    label="Share"
                    icon="share"
                    variant="plainOutlined"                
                />
                <TubeSubmit
                    onClick={() => {}}
                    label="Report"
                    icon="flag"
                    variant="plainOutlined"                
                />
            </div>
        </div>
        {video?.channel && (
            <>
                <div className="line"/>
                <div className='video-content-user' onClick={() => navigate(`/channel/${video?.channel.title_slug}`)}>
                    <div className='video-content-user-avatar' style={{backgroundImage: `url("${appUrl}/channel-banners/${video?.channel.logo}")`}}/>
                    <div className='video-content-user-name'>
                        {video?.channel.title}
                    </div>
                    <TubeSubmit
                        onClick={() => {}}
                        label="Subscribe"             
                    />
                </div>
            </>
        )}  
        {(((!video?.channel || video?.channel.show_user !== "2") && moduleUser) && showMore) && (
            <>
                <div className="line"/>
                <div className='video-content-user' onClick={() => navigate(`/profile/${video?.user_id}`)}>
                    <div className='video-content-user-avatar' style={{backgroundImage: `url("${appUrl}/${video?.userAvatar}")`}}/>
                    <div className='video-content-user-name'>
                        {video?.user}
                    </div>
                    <TubeSubmit
                        onClick={() => {}}
                        label="Subscribe"             
                    />
                </div>
            </>
        )}  
        {(video?.models && video?.models.length > 0 && showMore) && (
            <>
                <div className="line"/>
                <div className='video-content-models'>
                    <h2>Models</h2>
                    {video?.models.map(model => (
                        <>
                            <div className='video-content-model-avatar' style={{backgroundImage: `url("${model.avatar}")`}} onClick={() => navigate(`/model/${model.title_slug}`)}/>
                            <div className='video-content-model-name' onClick={() => navigate(`/model/${model.title_slug}`)}>
                                {model.name}
                            </div>
                        </>
                    ))}
                </div>
            </>
        )}
        { showMore && (
            <>
                <div className="line"/>
                <div className='album-content-categories'>
                    <h2>Categories</h2>
                    <div className="categories-content-tags">
                        {video?.categoriesNew && video?.categoriesNew.map(category => (
                            <TubeButton
                                label={capitalizeEveryWord(category.name)}
                                onClick={() => navigate(`/videos/${category.title_slug}`)}
                            />
                        ))}
                    </div>
                </div>
            </>
        )}
        {(video?.tagsNew && video?.tagsNew.length > 0 && showMore) && (
            <>
                <div className="line"/>
                <div className='album-content-categories'>
                    <h2>Tags</h2>
                    <div className="categories-content-tags">
                        {video?.tagsNew && video?.tagsNew.map(tag => (
                            <TubeButton
                                label={tag.name.toLowerCase()}
                                onClick={() => navigate(`/videos/tag/${tag.title_slug}`)}
                            />
                        ))}
                    </div>
                </div>
            </>
        )}
        <div className="line"/>
        <TubeSubmit
            onClick={() => {setShowMore(prevState => !prevState)}}
            label={showMore ? "Show less" : "Show more"}    
            className={"show-more"}         
        />
    </>
    );
}