import "./Channels.css";
import { NavLink, useParams } from "react-router-dom";
import Sidebar from "./Sidebar";
import { useEffect, useState } from "react";
import { appUrl, get } from "../../utils/fetch";
import { CameraAltOutlined, VideocamOutlined, ChatOutlined, FavoriteBorderOutlined, VisibilityOutlined } from "@mui/icons-material";
import TubeSubmit from "../form/TubeSubmit";
import DataTablePagination from "../dataTable/DataTablePagination";
import ChannelList from "../dataTable/ChannelList";
import { edit as editHeader } from '../../slices/headerSlice';
import { useDispatch, useSelector } from "react-redux";

export default function Channels(props){
    const { page } = useParams();
    const [ channels, setChannels ] = useState([]);
    const [ totalPages, setTotalPages ] = useState(1);

    const settings = useSelector(state => state.settings);
    const dispatch = useDispatch();

    useEffect(() => {
        window.scrollTo({
            top: 0
        });
    }, []);

    useEffect(() => {
        get(`${appUrl}/api/channels?limit=12&order=desc&column=id&page=${page}`, (result) => {
            setChannels(result.data.data);
            setTotalPages(result.data.last_page);
        });
        dispatch(editHeader({title: `Channels - ${settings.siteTitle}`}));
    }, [page]);

    const follow = (e) => {
        e.preventDefault();
    }
    
    return (
        <div className="categories-wrapper">
            <Sidebar videos />
            <div className="categories-content">
                <div className="categories-content-header">
                    <h1>Channels</h1>
                </div>
                <div className="categories-content-channels">
                    <ChannelList data={channels} />
                </div>
                <div className="categories-content-videos-pagination">
                    <DataTablePagination
                        page={page ? page : 1}
                        url={"/channels"}
                        totalPages={totalPages}
                    />
                </div>
            </div>
        </div>
    );
}