import { NavLink } from "react-router-dom";
import { appUrl, formatNumber } from "../../utils/fetch";
import { ChatOutlined, FavoriteBorderOutlined, VideocamOutlined, VisibilityOutlined } from "@mui/icons-material";
import { moduleUser } from "../../utils/modules";
import TubeSubmit from "../form/TubeSubmit";

export default function ChannelList(props){
    const { data } = props;

    const follow = (e) => {
        e.preventDefault();
    }
    
    return (
        <>
            {data && data.map(channel => (
                <NavLink to={`/channel/${channel.title_slug}`}>
                    <div className="categories-content-channels-channel"> 
                        <div className="categories-content-channels-channel-thumb" style={{backgroundImage: `url(${appUrl}/${channel.cover})`}}/>
                        <div className="categories-content-channels-channel-logo" style={{backgroundImage: `url(${appUrl}/${channel.logo})`}}/>
                        <div className='categories-content-channels-info'>
                            <span><VideocamOutlined /> {formatNumber(channel.videos)}</span>
                            <span><VisibilityOutlined /> {formatNumber(channel.videoViews)}</span>
                            {moduleUser && (
                                <>
                                    <span><FavoriteBorderOutlined /> 0</span>
                                    <span><ChatOutlined /> 0</span>
                                </>
                            )}
                        </div> 
                        {moduleUser && (
                            <TubeSubmit
                                label="Follow"
                                variant={"outlined"}
                                onClick={follow}
                            />
                        )}
                    </div>
                    <div className="categories-content-channels-name">{channel.title}</div>
                </NavLink>
            ))}
        </>
    ); 
}