import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    query: ""
}

export const searchSlice = createSlice({
    name: 'search',
    initialState: initialState,
    reducers: {
        edit: (state, payload) => {
            if(payload.payload === "default"){
                state = {
                    ...initialState,
                    meta: state.meta
                };
            }
            else {
                state = {
                    ...state, 
                    ...payload.payload
                };
            }
            return state;
        }
    },
})

export const { edit } = searchSlice.actions

export default searchSlice.reducer