import axios from 'axios';
import diacritics from 'diacritics';
const { API_URL, FRONTEND_URL } = window.config;

const objectKeys = [
    "photosToUpload",
    "categories",
    "categoriesNew",
    "tagsNew",
    "tags",
    "languages",
    "country",
    "languages",
    "channels",
    "models"
]

export const appUrl = API_URL;
export const frontendUrl = FRONTEND_URL + "/";

export function post(url, body, done = () => {}, submit = () => {}, required = [], validation = []){
    let err = [];
    required.forEach((item, i) => {
        if(validation.length && validation[i] === "email" && !validateEmail(body[item])){
            err = [...err, item];
        }
        if(validation.length && validation[i] === "short" && (!body[item].length || body[item].length > 10)){
            err = [...err, item];
        }
        if(validation.length && validation[i] === "shortOrNull" && body[item].length && body[item].length > 10){
            err = [...err, item];
        }
        if(validation.length && validation[i] === "notNull" && (!body[item] || !body[item].length)){
            err = [...err, item];
        }
    });
    if(!err || !err.length){
        submit();
        let formData = new FormData();
        Object.keys(body).forEach(key => {
            if(objectKeys.includes(key)){
                Object.keys(body[key]).forEach(subKey => {
                    if(typeof body[key][subKey] === "object" && !body[key][subKey].size){
                        formData.append(`${key}[]`, body[key][subKey].id);
                    }
                    else {
                        formData.append(`${key}[]`, body[key][subKey]);   
                    }
                })
            }
            else {
                formData.append(key, body[key]);
            }
        });
        axios.post(url, formData, {
                headers: {
                    //'Authorization' : `Bearer ${cookies.get("auth")}`,
                    'Content-Type' : 'multipart/form-data'
                }
            })
            .then(function (response) {
                done(response);
            })
            .catch(function (error) {
            })
            .finally(function () {
            });
    }
    else {
        done({internalError: err});
    }
}

export function del(url, done = () => {}, submit = () => {}, body = {}){
    submit();
    axios.delete(url, body)
        .then(function (response) {
            console.log(response);
        })
        .catch(function (error) {
            console.log(error);
        })
        .finally(function () {
            done();    
        });
}

export function get(url, done = () => {}, submit = () => {}){
    submit();
    axios.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(function (response) {
            done(response);
        })
        .catch(function (error) {
            done(error);
        })
        .finally(function () {
        });
}

export function slugify(str = "") {
    str = diacritics.remove(str)
        .replace(/^\s+|\s+$/g, '')
        .toLowerCase()
        .replace(/[^\w\s-]/g, '')
        .replace(/\s+/g, '-');
    return str;
}

export function validateEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}

export function calculateAge(birthdate) {
    const today = new Date();
    const birthDate = new Date(birthdate);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
}

export function capitalizeEveryWord(str) {
    // Split the string into an array of words
    let words = str.split(' ');
    
    // Capitalize the first letter of each word
    for (let i = 0; i < words.length; i++) {
        words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
    }
    
    // Join the words back together into a single string
    return words.join(' ');
}

export function formatNumber(num) {
    if (num >= 1_000_000_000) {
        return (Math.floor(num / 10_000_000) / 100).toFixed(1) + "B";
    } else if (num >= 1_000_000) {
        return (Math.floor(num / 10_000) / 100).toFixed(1) + "M";
    } else if (num >= 1_000) {
        return (Math.floor(num / 10) / 100).toFixed(1) + "K";
    }
    return Math.floor(num * 100) / 100 + "";
}