import { NavLink } from "react-router-dom";
import { appUrl } from "../../utils/fetch";
import { CameraAltOutlined, ChatOutlined, FavoriteBorderOutlined, VideocamOutlined, VisibilityOutlined } from "@mui/icons-material";
import { moduleUser } from "../../utils/modules";

export default function ModelList(props){
    const { data } = props;
    
    return (
        <>
            {data.map(model => (
                <NavLink to={`/model/${model.title_slug}`} key={model.id}>
                    <div className="categories-content-models-model">
                        <div className="categories-content-models-model-thumb" style={{backgroundImage: `url("${appUrl}/${model.nameAvatar}")`}} />
                        <div className="categories-content-models-model-media"><VideocamOutlined /> {model.videos}</div>    
                        <div className="categories-content-models-model-media"><CameraAltOutlined /> {model.albums}</div>    
                    </div>
                    <div className="categories-content-models-name">{model.stage_name}</div>
                    {/* <div className='categories-content-models-info'>
                        <VisibilityOutlined /> 0
                        {moduleUser && (
                            <>
                                <FavoriteBorderOutlined /> 0
                                <ChatOutlined /> 0
                            </>
                        )}
                    </div> */}
                </NavLink>
            ))}
        </>
    ); 
}