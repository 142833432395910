import "./Footer.css";
import NavigationLink from "./NavigationLink";
import Logo from "../../assets/logo.svg";
import { SocialIcon } from 'react-social-icons'
import { useEffect, useState } from "react";
import { appUrl, frontendUrl, get } from "../../utils/fetch";
import { useSelector } from "react-redux";
import { moduleUser } from "../../utils/modules";
import { StarOutlineOutlined } from "@mui/icons-material";

export default function Footer(props){

    const [ pages, setPages ] = useState({});

    useEffect(() => {
        get(`${appUrl}/api/content`, (result) => {setPages(result.data)});
    }, []);

    const settings = useSelector((state) => state.settings);

    const domainName = window.location.hostname.startsWith('www.') 
        ? window.location.hostname 
        : `www.${window.location.hostname}`;

    return (
        <div className="footer">
            <div className="footer-content">
                <div className="footer-content-ads">
                    <div className="footer-content-ad" />
                    <div className="footer-content-ad" />
                    <div className="footer-content-ad" />
                </div>
                <div className="footer-content-description">
                    {settings?.siteDescription}
                </div>
                <div className="footer-content-navigation">
                    <div className="footer-content-navigation-backlinks">
                        {settings?.backLinks && Object.keys(JSON.parse(settings.backLinks))
                            .map((key) => JSON.parse(settings.backLinks)[key]) 
                            .filter(item => parseInt(item.status) === 1) 
                            .map((item, index) => ( 
                                <NavigationLink
                                    variant="plain"
                                    src={(item.link && !item.link.startsWith("http://") && !item.link.startsWith("https://") ? "http://" + item.link : item.link)}
                                    linkName={item.name}
                                    target={item.target}
                                    rel={item.rel === "0" ? "" : item.rel}
                                    key={index}
                                    icon={<StarOutlineOutlined />}
                                />
                            ))
                        }
                    </div>
                    <div className="footer-content-navigation-logo-mobile">
                        <img src={`${appUrl}/assets/file_footer_logo.png`} alt="Nakedmilfs.net"/>
                    </div>
                    <div className="footer-content-navigation-links">
                        {settings?.mainMenu && 
                            Object.keys(JSON.parse(settings.mainMenu))
                                .map((key) => JSON.parse(settings.mainMenu)[key]) // Extract items
                                .filter(item => parseInt(item.status) === 1) // Filter by status
                                .map((item, index) => ( // Render filtered items
                                    <NavigationLink
                                        linkName={item.name}
                                        src={
                                            item.page === "a" ? "/videos" : 
                                            item.page === "b" ? "/galleries" : 
                                            item.page === "c" ? "/channels" : 
                                            item.page === "d" ? "/models" : 
                                            item.page === "e" ? "/categories" : 
                                            item.page === "f" ? "/tags" :
                                            settings?.customPagesIds.includes(parseInt(item.page)) ? `/content/${item.page}` :
                                            item.page.startsWith(frontendUrl) ? item.page.replace(frontendUrl, "") :
                                            (item.page && !item.page.startsWith("http://") && !item.page.startsWith("https://") ? "http://" + item.page : item.page)
                                        }
                                        target={item.target}
                                        key={index}
                                    />
                                ))
                        }
                        <div className="flex-right">
                            <div className="footer-content-navigation-logo">
                                <img src={`${appUrl}/assets/file_footer_logo.png`} alt="Nakedmilfs.net"/>
                            </div>
                        </div>
                    </div>
                    {moduleUser && (
                        <div className="footer-content-navigation-column">
                            <h3>Account</h3>
                            <NavigationLink
                                variant="plain"
                                src="/"
                                linkName="Login"
                            />
                            <NavigationLink
                                variant="plain"
                                src="/"
                                linkName="Create free account"
                            />
                            <NavigationLink
                                variant="plain"
                                src="/"
                                linkName="Contact"
                            />
                            <NavigationLink
                                variant="plain"
                                src="/"
                                linkName="Help center"
                            />
                        </div>
                    )}
                </div>  
                <div className="footer-content-copyright">
                    <div className="footer-content-navigation-pages">
                        {pages?.data && pages?.data.map(page => (
                            <NavigationLink
                                variant="plain"
                                src={`/content/${page.id}`}
                                linkName={page.title}
                                key={page.id}
                            />   
                        ))}
                        <div className="flex-right">
                            <div className="footer-content-copyright-social">
                                {(settings && settings.socialMedia) && Object.keys(JSON.parse(settings.socialMedia))
                                    .map((key) => JSON.parse(settings.socialMedia)[key])
                                    .filter(item => (item.url))
                                    .map((item, index) => (
                                        <SocialIcon 
                                            network={item.name}
                                            url={(item.url && !item.url.startsWith("http://") && !item.url.startsWith("https://") ? "http://" + item.url : item.url)}
                                            bgColor="rgb(65, 78, 92)"
                                            key={index}
                                            target="_blank"
                                        />
                                    )
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="line" />
                    {domainName} &copy; Copyright {new Date().getFullYear()}
                </div>  
            </div>
        </div>
    );
}