import { configureStore } from '@reduxjs/toolkit';
import settingsSlice from './slices/settingsSlice';
import headerSlice from './slices/headerSlice';
import searchSlice from './slices/searchSlice';

export default configureStore({
    reducer: {
        settings: settingsSlice,
        header: headerSlice,
        search: searchSlice,
        default: {}
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});