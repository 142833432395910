
import "./Page.css";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { appUrl, get } from "../../utils/fetch";

export default function Page(props){
    const [ page, setPage ] = useState([]);
    const { id } = useParams();

    useEffect(() => {
        get(`${appUrl}/api/content/${id}`, (result) => {
            setPage(result.data);
        });
    }, [id]);
    
    return (
        <div className="page-content">
            <div dangerouslySetInnerHTML={{ __html: page.content }} />
        </div>
    );
}