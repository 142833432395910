import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import NavigationLink from "./NavigationLink";
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import "./Navigation.css";
import { FormatListBulletedOutlined, PhotoCameraOutlined, StarBorderOutlined, SubscriptionsOutlined } from '@mui/icons-material';
import TubeSubmit from '../form/TubeSubmit';
import { moduleUser } from '../../utils/modules';
import { useDispatch, useSelector } from 'react-redux';
import { frontendUrl } from '../../utils/fetch';
import TubeRolldown from './TubeRolldown';
import { useEffect, useRef } from 'react';
import { edit as editHeader } from '../../slices/headerSlice';

function Navigation() {
    const dispatch = useDispatch();

    const settings = useSelector((state) => state.settings);
    const openMenu = useSelector(state => state.header.openMenu);
    
    const videoLinkRef = useRef(null)
    
    const iconStyle = {
        fontSize: 24,
        marginRight: 1,
        color: "inherit"
    }

    useEffect(() => {
        if(openMenu){
            const handleClick = () => {
                setTimeout(() => dispatch(editHeader({openMenu: false})), 100);
            };

            document.addEventListener("mousedown", handleClick, true);
            return () => {
                document.removeEventListener("mousedown", handleClick, true);
            };
        }
    }, [dispatch, openMenu]);

    return (
        <Paper elevation={0} className="navigation-wrapper">
            <div className={`navigation-block ${openMenu ? 'visible' : ''}`}>
                <MenuList 
                    className="navigation"
                    sx={{
                        width: "auto"
                    }}
                >
                    {settings?.mainMenu && 
                        Object.keys(JSON.parse(settings.mainMenu))
                            .map((key) => JSON.parse(settings.mainMenu)[key]) // Extract items
                            .filter(item => parseInt(item.status) === 1) // Filter by status
                            .map((item, index) => ( // Render filtered items
                                <NavigationLink 
                                    icon={
                                        item.page === "a" ? <VideocamOutlinedIcon sx={iconStyle}/> : 
                                        item.page === "b" ? <PhotoCameraOutlined sx={iconStyle}/> : 
                                        item.page === "c" ? <SubscriptionsOutlined sx={iconStyle}/> : 
                                        item.page === "d" ? <StarBorderOutlined sx={iconStyle}/> : 
                                        item.page === "e" ? <FormatListBulletedOutlined sx={iconStyle}/> : 
                                        item.page === "f" ? <LocalOfferOutlinedIcon sx={iconStyle}/> :
                                        null
                                    }
                                    linkName={item.name}
                                    src={
                                        item.page === "a" ? "/videos" : 
                                        item.page === "b" ? "/galleries" : 
                                        item.page === "c" ? "/channels" : 
                                        item.page === "d" ? "/models" : 
                                        item.page === "e" ? "/categories" : 
                                        item.page === "f" ? "/tags" :
                                        settings?.customPagesIds.includes(parseInt(item.page)) ? `/content/${item.page}` :
                                        item.page.startsWith(frontendUrl) ? item.page.replace(frontendUrl, "") :
                                        (item.page && !item.page.startsWith("http://") && !item.page.startsWith("https://") ? "http://" + item.page : item.page)
                                    }
                                    target={item.target}
                                    key={index}
                                    ref={
                                        item.page === "a" ? videoLinkRef :
                                        null
                                    }
                                    dropDown={
                                        item.page === "a" ? true :
                                        false
                                    }
                                />
                            ))
                    }
                    {moduleUser && (
                        <NavigationLink icon={<PeopleAltOutlinedIcon sx={iconStyle}/>} linkName="Community" src="/" />
                    )}
                </MenuList>
                {moduleUser && (
                    <div>
                        <TubeSubmit
                            label=""
                            redirectTo="/"
                            icon="signup"
                            variant={"plain"}
                        />
                        <TubeSubmit
                            label="Upload"
                            redirectTo="/"
                            icon="upload"
                            variant={"outlined"}
                        />
                    </div>
                )}
                <TubeRolldown
                    items={[
                        { label: "Newest videos", href: "/videos/newest-videos" },
                        { label: "Top rated", href: "/videos/top-rated" },
                        { label: "Most watched", href: "/videos/most-watched" }
                    ]}
                    hoverRef={videoLinkRef}
                />
            </div>
        </Paper>
    );
}

export default Navigation;