import { useDispatch, useSelector } from "react-redux";
import "./NotFound.css";
import { useEffect, useState } from "react";
import { edit } from "../../slices/headerSlice";
import TubeSubmit from "../form/TubeSubmit";
import Title from "../navigation/Title";
import { Link } from "react-router-dom";
import { appUrl, get } from "../../utils/fetch";
import VideoList from "../dataTable/VideoList";

export default function NotFound(){
    const dispatch = useDispatch();
    const settings = useSelector((state) => state.settings);

    const [ videos, setVideos ] = useState([]);

    useEffect(() => {
        dispatch(edit({title: "Page not found"}));
        get(`${appUrl}/api/videos?limit=4&order=desc&column=views`, (result) => setVideos(result.data.data));
    }, []);
    
    return (
        <div className="not-found-page">
            <div className="error-heading">{settings?.p404Heading}</div>
            <div className="error-message">{settings?.p404Subheading}</div>
            <Link to="/">
                <TubeSubmit
                    label={"Back to homepage"}
                />
            </Link>
            <div
                className="not-found-background"
                style={{
                    backgroundImage: `url('${appUrl}/assets/file_p404_background.png')`
                }}
            />
            <Title
                title={"Trending videos"}
                fontSize={24}
            />
            <div className="video-list">
                <VideoList
                    data={videos}
                />
            </div>
        </div>
    );
}