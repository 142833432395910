import { ChatOutlined, Code, ContentCopy, FavoriteBorderOutlined, Reply, VisibilityOutlined } from '@mui/icons-material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { appUrl, capitalizeEveryWord, frontendUrl, get, post } from '../../utils/fetch';
import TubeButton from '../form/TubeChip';
import TubeSubmit from '../form/TubeSubmit';
import Sidebar from './Sidebar';
import Player from '../ux/Player';
import "./Video.css";
import { moduleUser } from '../../utils/modules';
import VideoList from '../dataTable/VideoList';
import { edit as editHeader } from '../../slices/headerSlice';
import { useDispatch, useSelector } from "react-redux";
import VideoDetail from './VideoDetail';
import DialogComponent from '../dialog/Dialog';
import Tabs from '../navigation/Tabs';
import { SocialIcon } from 'react-social-icons';
import TextInput from '../form/TextInput';
import TextArea from '../form/TextArea';
import { TextField } from '@mui/material';

function Video(props) {
    const { id, hash, shareTab, embed } = useParams();

    const [ video, setVideo ] = useState({
        models: [],
        ad1: [],
        ad2: [],
        ad4: [],
        ad5: [],
    });
    const shareInit = {
        isOpen: false,
        active: shareTab || "social"
    }
    const [ share, setShare ] = useState(shareInit);
    const [ isCopiedVideoUrl, setIsCopiedVideoUrl ] = useState(false);
    const [ embedCode, setEmbedCode ] = useState("");

    const navigate = useNavigate();
    const settings = useSelector(state => state.settings);
    const dispatch = useDispatch();
    const videoUrl = `${frontendUrl}video/${id}`;

    useEffect(() => {
        let link;
        if(id){
            link = `video/${id}`;
        }
        if(embed){
            link = `video/${embed}`;
        }
        if(hash){
            link = `video-preview/${hash}/adminHashPlaceholder`;
        }
        get(`${appUrl}/api/${link}`, (result) => {
            if (result.response?.status === 404) {
                navigate('/404');
                return;
            }
            else {
                setVideo(result.data)
                dispatch(editHeader({title: `${result.data.name} - ${settings.siteTitle}`}));
                setEmbedCode(`<iframe width="650" height="366" src="${frontendUrl}embed/${result.data.title_slug}" title="${settings.siteTitle} video player" frameborder="0" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`);
            }
        });
        window.scrollTo({
            top: 0
        });
    }, [id, hash]);

    useEffect(() => {
        if(shareTab){
            setShare(prevState => ({
                ...prevState,
                active: shareTab,
                isOpen: true
            }))
        }
    }, [ shareTab ]);
    
    const copyVideoUrl = () => {
        setIsCopiedVideoUrl(true);
        setTimeout(() => setIsCopiedVideoUrl(false), 3000);
        navigator.clipboard.writeText(videoUrl);
    }

    const shareOnTwitter = () => {
        const url = encodeURIComponent(videoUrl);
        const text = encodeURIComponent("Check this video!");
        const twitterUrl = `https://x.com/intent/tweet?url=${url}&text=${text}`;
        window.open(twitterUrl, "_blank");
    };

    const shareOnReddit = () => {
        const url = encodeURIComponent(videoUrl);
        const title = encodeURIComponent(video?.name);
        const redditUrl = `https://www.reddit.com/submit?url=${url}&title=${title}`;
        window.open(redditUrl, "_blank");
    };

    const shareOnVK = () => {
        const url = encodeURIComponent(videoUrl);
        const title = encodeURIComponent(video?.name);
        const vkUrl = `https://vk.com/share.php?url=${url}&title=${title}`;
        window.open(vkUrl, "_blank");
    };

    const shareOnTelegram = () => {
        const url = encodeURIComponent(videoUrl);
        const text = encodeURIComponent(video?.name);
        const telegramUrl = `https://t.me/share/url?url=${url}&text=${text}`;
        window.open(telegramUrl, "_blank");
    };
    
    return (
        <div className='video-wrapper'>
            <div className='video-content'>
                <div className={`video-content-video ${video.vertical ? 'vertical' : ''}`}>
                    {video?.embed ? (
                        <iframe 
                            className={"video-file"}
                            src={video?.embed}
                            frameborder="0"
                            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowfullscreen
                            title={video?.name}
                        />
                    ) : (
                        <Player
                            src={video?.file && `${appUrl}/videos/${video?.file}`}
                            length={video?.lengthRaw}
                            preview={(video?.thumbnail && video?.thumbnail !== "no-preview") && `${appUrl}/videos/thumbs/${video?.thumbnail}`}
                            videoData={video?.videoData}
                            ads={{
                                ad1: video?.ad1,
                                ad2: video?.ad2,
                                ad5: video?.ad5
                            }}
                            vertical={video.vertical}
                        />
                    )}
                </div>
                {!embed && (
                    <>
                        <VideoDetail 
                            video={video}
                            shareFunc={() => {
                                setShare({ ...shareInit, isOpen: true });
                                navigate(`/video/${id}/share/social`);
                            }} />
                        <div className="line"/>
                        <h2>Related</h2>
                        <div className="categories-content-videos">
                            <VideoList data={video?.related} />
                        </div>
                    </>
                )}
            </div>
            {!embed && (
                <>
                    <Sidebar videos width={350} ad={video?.ad4} />
                    <DialogComponent
                        onClose={() => { 
                            setShare(shareInit);
                            navigate(`/video/${id}`);
                        }}
                        open={share.isOpen}
                        title={"Share"}
                        className={'video-share-dialog'}
                        content={(
                            <Tabs
                                title=""
                                tabs={[
                                    { 
                                        title: `Share`,
                                        href: `/video/${id}/share/social`,
                                        icon: <Reply/>,
                                        active: share.active === "social",
                                        content: (
                                            <>
                                                <div className='mt-20 mb-10'>
                                                    Share on social media
                                                </div>
                                                <div>
                                                    <SocialIcon
                                                        network="x"
                                                        url={""}
                                                        fgColor={settings.themeColorWhite}
                                                        bgColor={settings.themeColorButton}
                                                        className='mr-5'
                                                        onClick={() => shareOnTwitter()}
                                                    />
                                                    <SocialIcon
                                                        network="reddit"
                                                        url={""}
                                                        fgColor={settings.themeColorWhite}
                                                        bgColor={settings.themeColorButton}
                                                        className='mr-5'
                                                        onClick={() => shareOnReddit()}
                                                    />
                                                    <SocialIcon
                                                        network="telegram"
                                                        url={""}
                                                        fgColor={settings.themeColorWhite}
                                                        bgColor={settings.themeColorButton}
                                                        className='mr-5'
                                                        onClick={() => shareOnTelegram()}
                                                    />
                                                    <SocialIcon
                                                        network="vk"
                                                        url={""}
                                                        fgColor={settings.themeColorWhite}
                                                        bgColor={settings.themeColorButton}
                                                        className='mr-5'
                                                        onClick={() => shareOnVK()}
                                                    />
                                                </div>
                                                <div className='mt-20 mb-10'>
                                                    Copy video link
                                                </div>
                                                <TextInput
                                                    noRedux
                                                    noReduxOnChange={() => {}}
                                                    noReduxValue={videoUrl}
                                                    readOnly={true}
                                                    adornmentIcon={<ContentCopy />}
                                                    adornmentLabel="Copy link"
                                                    adornmentOnClick={copyVideoUrl}
                                                    helperText={isCopiedVideoUrl && "Video url has been copied!"}
                                                />
                                            </>
                                        )
                                    },
                                    { 
                                        title: `Embed`,
                                        href: `/video/${id}/share/embed`,
                                        icon: <Code/>,
                                        active: share.active === "embed",
                                        content: (
                                            <>
                                                <div className='mt-20 mb-10'>
                                                    Embed video link
                                                </div>
                                                <TextField 
                                                    id={'embedCode'}
                                                    label={''}
                                                    variant="outlined"
                                                    className={`text-area`}
                                                    value={embedCode}
                                                    readOnly={true}
                                                    multiline
                                                    rows={4}
                                                />
                                            </>
                                        )
                                    }
                                ]}
                            />
                        )}
                        buttons={[
                            {
                                variant: "outlined",
                                label: "Close",
                                color: "primary",
                                onClick: () => { 
                                    setShare(shareInit);
                                    navigate(`/video/${id}`);
                                },
                            }
                        ]}
                    />
                </>
            )}
        </div>
    );
}

export default Video;